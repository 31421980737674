import Vue from 'vue'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import Fragment from 'vue-fragment'

import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import './styles/app.pcss'

// import './mocks';

Vue.config.productionTip = false

Vue.use(VueAwesomeSwiper)
Vue.use(Fragment.Plugin)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
