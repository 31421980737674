<template lang="pug">
  div.choose-store(
    v-if="isShow"
  )
    .choose-store__container(
      v-if="!profileIsLoading"
    )
      .choose-store__logo.logo: img(src="images/static/logo.svg", alt="logo")
      .choose-store__body
        span.choose-store__title {{ $t('modals.titles.chooseShop') }}
        ul.choose-store__list
          li(
            v-for="customer in profile.customers" :key="customer.id"
            @click="onClickCustomer(customer)"
            class="choose-store__item"
          )
            span.choose-store__item-name {{ customer.name }}
            span.choose-store__item-address {{ customer.shipping_address }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FontIcon from '@/components/FontIcon.vue'

export default {
  name: 'ChooseStore',
  data () {
    return {
      isShow: true,
      savedID: null,
      liveSessionTimer: null
    }
  },
  methods: {
    ...mapActions(['setAppSettings', 'loadProfileSubData', 'setLiveSessionState']),
    onClickCustomer (customer) {
      this.setAppSettings({ currentCustomerId: customer.id })
      this.loadProfileSubData(customer.id)
      this.setLiveSessionState(true)
      sessionStorage.setItem('customerID', customer.id)
      this.isShow = false
    }
  },
  computed: {
    ...mapGetters(['profile', 'profileIsLoading', 'isLiveSession']),
    customersLength () {
      // eslint-disable-next-line no-prototype-builtins
      return this.profile && this.profile.hasOwnProperty('customers') && this.profile.customers.length > 1
    }
  },
  watch: {
    isLiveSession (val) {
      if (!val) {
        this.isShow = true
      }
    },
    customersLength () {
      if (!this.savedID && this.customersLength) {
        this.isShow = true
      }
    },
    isShow (val) {
      if (val) {
        document.querySelector('body').style.position = 'fixed'
      } else {
        document.querySelector('body').style.position = ''
      }
    }
  },
  created () {
    this.savedID = sessionStorage.getItem('customerID')
    if (this.savedID) {
      this.isShow = false
    } else if (this.customersLength) {
      this.isShow = true
    }

    if (!this.isLiveSession) {
      this.isShow = true
    }
  },
  components: {
    FontIcon
  }
}
</script>

<style scoped>
  .choose-store {
    background: linear-gradient(315deg, #4048EF 0%, #5A7BEF 100%);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 55px 40px;
    overflow-y: auto;
  }

  .choose-store__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 97%;
    max-width: 550px;
    margin: auto;
    text-align: center;
  }

  .choose-store__logo {
    margin-bottom: 70px;

    @media screen and (max-width: 767px) {
      margin-bottom: 40px;
    }
  }

  .choose-store__body {
    max-width: 480px;
    width: 100%;
    background: #3C48CC;
    border-radius: 15px;
    overflow: hidden;
  }

  .choose-store__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    display: block;
    padding: 24px 0;
  }

  .choose-store__item-name {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
  }

  .choose-store__item-address {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    color: #CCCCCC;
    margin-left: 16px;
  }

  .choose-store__item {
    padding: 26px 15px;
    cursor: pointer;
    text-align: left;
    padding-left: 40px;
    background-color: transparent;
    transition: background-color 0.5s;
  }

  .choose-store__item:hover {
    background-color: #242A6D;
  }

  @media (max-width: 767px) {
    .choose-store {
      padding: 1rem;
    }

    .choose-store__title {
      padding: 20px 0;
      font-size: 20px;
    }

    .choose-store__item {
      padding: 15px 15px;
    }

    .choose-store__item-name {
      font-size: 14px;
    }
  }
</style>
