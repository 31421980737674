import Auth from '@/api/Auth'

const mutations = {
  SET_TOKEN: 'AUTH/DATA/SET_TOKEN',
  RESET_TOKEN: 'AUTH/DATA/RESET_TOKEN',
  SET_ERROR: 'AUTH/DATA/SET_ERROR',
  RESET_ERROR: 'AUTH/DATA/RESET_ERROR',
  SET_STATUS: 'AUTH/DATA/SET_STATUS',
  RESET_STATUS: 'AUTH/DATA/RESET_STATUS',
  SET_IS_LOADING: 'AUTH/DATA/SET_IS_LOADING'
}

export default {
  state: {
    data: localStorage.getItem('user-token') || '',
    isLoading: false,
    status: '',
    error: null
  },
  mutations: {
    [mutations.SET_TOKEN]: (state, payload) => {
      state.data = payload
    },
    [mutations.RESET_TOKEN]: (state) => {
      state.data = ''
    },
    [mutations.SET_ERROR]: (state, payload) => {
      state.error = payload
    },
    [mutations.RESET_ERROR]: (state) => {
      state.error = null
    },
    [mutations.SET_STATUS]: (state, payload) => {
      state.status = payload
    },
    [mutations.RESET_STATUS]: (state) => {
      state.status = ''
    },
    [mutations.SET_IS_LOADING]: (state, payload) => {
      state.isLoading = payload
    }
  },
  actions: {
    async login (ctx, payload) {
      ctx.commit(mutations.SET_IS_LOADING, true)
      try {
        const response = await Auth.init().login(payload)
        ctx.commit(mutations.SET_STATUS, 'success')
        return response
      } catch (e) {
        ctx.commit(mutations.SET_ERROR, e)
        ctx.commit(mutations.SET_STATUS, 'error')
        throw e
      } finally {
        ctx.commit(mutations.SET_IS_LOADING, false)
      }
    },
    setToken (ctx, payload) {
      localStorage.setItem('token', payload)
      ctx.commit(mutations.SET_TOKEN, payload)
    },
    resetToken (ctx) {
      localStorage.removeItem('token')
      ctx.commit(mutations.RESET_TOKEN)
    },
    resetAuthError (ctx) {
      ctx.commit(mutations.RESET_ERROR)
    },
    resetAuthStatus (ctx) {
      ctx.commit(mutations.RESET_STATUS)
    },
    logout (ctx) {
      ctx.dispatch('resetToken')
      window.location.href = '/login'
    }
  },
  getters: {
    isAuthLoading (state) {
      return state.isLoading
    },
    authError (state) {
      return state.error
    },
    authStatus (state) {
      return state.status
    }
  }
}
