import { omit } from 'lodash'

const mutations = {
  SET_DATA: 'APP_SETTINGS/DATA/SET_DATA',
  REMOVE_DATA: 'APP_SETTINGS/DATA/REMOVE_DATA',
  CLEAR_DATA: 'APP_SETTINGS/DATA/CLEAR_DATA'
}

let baseAppSettings = {}

try {
  const strAppSettings = localStorage.getItem('appSettings')
  baseAppSettings = JSON.parse(strAppSettings)
} catch (e) {
  // eslint-disable-next-line no-console
  console.error(e)
}

export default {
  state: {
    data: baseAppSettings
  },
  mutations: {
    [mutations.SET_DATA]: (state, payload) => {
      state.data = {
        ...state.data,
        ...payload
      }
    },
    [mutations.REMOVE_DATA]: (state, payload) => {
      state.data = omit(state.data, payload)
    },
    [mutations.clear]: (state) => {
      state.data = {}
    }
  },
  actions: {
    setAppSettings (ctx, payload) {
      ctx.commit(mutations.SET_DATA, payload)
      localStorage.setItem('appSettings', JSON.stringify(ctx.state.data))
    },
    removeAppSettings (ctx, payload) {
      ctx.commit(mutations.REMOVE_DATA, payload)
      localStorage.setItem('appSettings', JSON.stringify(ctx.state.data))
    },
    clearAppSettings (ctx) {
      ctx.commit(mutations.CLEAR_DATA)
      localStorage.setItem('appSettings', JSON.stringify(ctx.state.data))
    }
  },
  getters: {
    appSettings (state) {
      return state.data
    }
  }
}
