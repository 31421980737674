<template lang="pug">
  span(:class="className")
    slot
</template>

<script>
export default {
  name: 'LabelStatus',
  props: {
    color: {
      type: String
    }
  },
  computed: {
    className () {
      const classes = ['label-status']
      if (this.color) {
        classes.push(`label-status--${this.color}`)
      }

      return classes
    }
  }
}
</script>

<style scoped>

</style>
