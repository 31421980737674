import Customer from '@/api/Customer'

const mutations = {
  SET_DATA: 'CUSTOMER/DATA/SET_DATA',
  SET_LOADING: 'CUSTOMER/DATA/SET_LOADING',
  CLEAR_DATA: 'CUSTOMER/DATA/CLEAR_DATA'
}

export default {
  state: {
    data: [],
    isLoading: false
  },
  mutations: {
    [mutations.SET_DATA] (state, payload) {
      state.data = payload
    },
    [mutations.SET_LOADING] (state, payload) {
      state.isLoading = payload
    },
    [mutations.CLEAR_DATA] (state) {
      state.data = []
    }
  },
  actions: {
    async loadingCustomers (ctx) {
      ctx.commit(mutations.SET_LOADING, true)
      try {
        const response = await Customer.init().get()
        ctx.commit(mutations.SET_DATA, response.data)
        return response
      } finally {
        ctx.commit(mutations.SET_LOADING, false)
      }
    }
  },
  getters: {
    customers (state) {
      return state.data
    },
    customersLading (state) {
      return state.isLoading
    }
  }
}
