<template lang="pug">
  .section-progressbar
    .container.section-progressbar__container
      .progressbar
        .progressbar__line.progressbar__line--loading
          .progressbar__percent.progressbar__percent--loading(
            style="width: " + progressPercent + ";"
          )
        .progressbar__list
          .progressbar__label.skeleton
            .skeleton__line.skeleton__line--size-s
          .progressbar__label.skeleton
            .skeleton__line.skeleton__line--size-s
          .progressbar__label.skeleton
            .skeleton__line.skeleton__line--size-s
          .progressbar__label.skeleton
            .skeleton__line.skeleton__line--size-s
          .progressbar__label.skeleton
            .skeleton__line.skeleton__line--size-s

</template>

<script>
export default {
  name: 'ProgressBarLoading'
}
</script>

<style scoped>

</style>
