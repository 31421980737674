export default (data) => {
  switch (data) {
    case 'open':
      return 'green'
    case 'invoice':
      return 'blue'
    case 'assembly':
      return 'gray'
    case 'shipment':
      return 'red'
    default:
      return ''
  }
}
