<template lang="pug">
  #panel-order.panel-order(:class="panelOrderClassNames")
    .panel-order__close.js-panel-order-close
      button.button-compact(class="button-compact--light", type="button" @click="closePanelOrder")
        font-icon(name="close", class-name="button-compact__icon")
    //- tabs
    .panel-order__tabs.tabs.js-tabs
      //- header
      .tabs__header.panel-order__tabs-panel
        //- link
        a(
          ref="tabs"
          :class="generateTabLinkClass('items')"
          @click="onClickTab('items')"
        )
          font-icon(name="shopping-cart")
          span.tabs__text {{$t('tabs.items')}}
          span.tabs__counter.js-counter(v-if="basketItems.length")
            span.tabs__counter-text {{totalAmount}}
        //- link
        a(
          ref="tabs"
          :class="generateTabLinkClass('orders')"
          @click="onClickTab('orders')"
        )
          font-icon(name="widgets")
          span.tabs__text {{$t('tabs.orders')}}

        a(
          ref="tabs"
          :class="generateTabLinkClass('documents')"
          @click="onClickTab('documents')"
        )
          font-icon(name="file")
          span.tabs__text {{$t('tabs.documents')}}

        a(
          ref="tabs"
          :class="generateTabLinkClass('subscribe')"
          @click="onClickTab('subscribe')"
        )
          font-icon(name="notifications")
          span.tabs__text {{$t('tabs.subscribe')}}
          span.tabs__counter.js-counter(v-if="subscribeCount > 0")
            span.tabs__counter-text {{subscribeCount}}
      //- body
      .tabs__body
        #table-items.order-table(:class="generateTabClass('items')")
          .container.order-table__container
            items-table(v-if="(isRefresh && basketItems.length) || (!isLoadingBasket && basketItems.length)" )
            empty-content-items(
              v-if="(isRefresh && !basketItems.length) || (!isLoadingBasket && !basketItems.length)"
              @closePanel="closePanelOrder"
            )
            .table-wrap.table-wrap--loading(v-if="!isRefresh && isLoadingBasket")
              .skeleton
                .skeleton__line.skeleton__line--light.skeleton__line--size-table

        #table-order.order-table(:class="generateTabClass('orders')")
          .container.order-table__container
            form.order-table__form
              .order-table__title(v-if="basketItems.length")
                font-icon(name="info")
                span
                | {{$t('messages.unfinishedOrder')}}
                button(
                  type="button"
                  class="button button--mobile-compact js-panel-order-close"
                  @click="onClickTab('items')"
                )
                  font-icon(name="shopping-cart" class-name="button__icon")
                  span.button__text {{$t('buttons.continue')}}
              orders-table(v-if="!isOrdersLoading && !profileIsLoading && orders.length")
              empty-orders-state(v-if="!isOrdersLoading && !profileIsLoading && !orders.length")
              .table-wrap.table-wrap--loading(v-if="!isRefresh && (isOrdersLoading || profileIsLoading)")
                .skeleton
                  .skeleton__line.skeleton__line--light.skeleton__line--size-table
        #table-documents.order-table(:class="generateTabClass('documents')")
          .container.order-table__container
            documents-table(v-if="isRefresh || (!isDocumentsLoading && !profileIsLoading)")
            .table-wrap.table-wrap--loading(v-if="!isRefresh && (isDocumentsLoading || profileIsLoading)")
              .skeleton
                .skeleton__line.skeleton__line--light.skeleton__line--size-table
        #table-documents.order-table(:class="generateTabClass('subscribe')")
          .container.order-table__container
            subscribe-table(v-if="isRefresh || (!isSubscribesLoading && !profileIsLoading)")
            .table-wrap.table-wrap--loading(v-if="!isRefresh && (isSubscribesLoading || profileIsLoading)")
              .skeleton
                .skeleton__line.skeleton__line--light.skeleton__line--size-table
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FontIcon from '@/components/FontIcon.vue'
import ItemsTable from '@/components/ItemsTable.vue'
import OrdersTable from '@/components/OrdersTable.vue'
import withBasketItems from '@/mixins/withBasketItems'
import EmptyContentItems from '@/components/EmptyContentItems.vue'
import EmptyOrdersState from '@/components/EmptyOrdersState.vue'
import withPanelOrderControls from '@/mixins/withPanelOrderControls'
import DocumentsTable from '@/components/DocumentsTable.vue'
import SubscribeTable from '@/components/SubscribeTable.vue'

export default {
  name: 'PanelOrder',
  props: {
    isRefresh: Boolean
  },
  methods: {
    ...mapActions(['setLiveSessionState', 'selectPanelOrderTab', 'togglePanelOrder', 'removeItemFromBasket', 'removeSubscribe', 'loadOrders', 'loadDocuments', 'getSubscribes']),
    onClickTab (tabName) {
      this.setLiveSessionState(true)
      this.selectPanelOrderTab(tabName)
      if (!this.isOpenedPanelOrder) {
        this.togglePanelOrder(true)
      }
      this.toggleBodyClass('open')
      this.toggleFixedTopClass('add')
    },
    generateTabLinkClass (tabName) {
      const classObj = { 'tabs__link--active': tabName === this.currentPanelOrderTab }
      return ['tabs__link', classObj]
    },
    generateTabClass (tabName) {
      const classObj = { 'tabs__item--active': tabName === this.currentPanelOrderTab }
      return ['tabs__item', classObj]
    }
  },
  computed: {
    ...mapGetters([
      'headerDropdownsState',
      'isOrdersLoading',
      'orders',
      'profile',
      'subscribes',
      'appSettings',
      'currentPanelOrderTab',
      'isOpenedPanelOrder',
      'profileIsLoading',
      'isDocumentsLoading',
      'isShopDataLoading',
      'profileIsLoading',
      'customersLading',
      'isSubscribesLoading'
    ]),
    subscribeCount () {
      let count = 0

      // eslint-disable-next-line no-prototype-builtins
      if (this.subscribes.hasOwnProperty('count')) {
        count = this.subscribes.count
      // eslint-disable-next-line no-prototype-builtins
      } else if (this.profile !== null && this.profile.hasOwnProperty('customer_user_subscribes_count')) {
        count = this.profile.customer_user_subscribes_count
      }

      return count
    },
    isLoadingBasket () {
      // eslint-disable-next-line max-len
      return this.isShopDataLoading || this.profileIsLoading || this.customersLading || this.isSubscribesLoading
    },
    panelOrderClassNames () {
      return {
        'panel-order--open': this.isOpenedPanelOrder,
        'panel-order--hide-shadow': this.headerDropdownsState
      }
    }
  },
  watch: {
    isShopDataLoading (val) {
      this.basketItems.forEach(obj => {
        if (obj.product.future_stock_movement !== null) {
          this.removeItemFromBasket(obj.index)
        }
      })

      // this.subscribes.forEach(obj => {
      //   const product = this.products.find(product => product.id === obj.id)
      //   if (product !== undefined && product.future_stock_movement === null) {
      //     this.removeSubscribe(obj.id)
      //   }
      // })
    },
    currentPanelOrderTab (val) {
      if (val === 'orders') {
        this.loadOrders(this.currentCustomer.id)
      }
      if (val === 'documents') {
        this.loadDocuments(this.currentCustomer.id)
      }
      if (val === 'subscribe') {
        this.getSubscribes(this.currentCustomer.id)
      }
    }
  },
  mixins: [
    withBasketItems,
    withPanelOrderControls
  ],
  components: {
    SubscribeTable,
    DocumentsTable,
    EmptyOrdersState,
    EmptyContentItems,
    OrdersTable,
    ItemsTable,
    FontIcon
  }
}
</script>

<style scoped>
  .tabs__counter-text {
    padding: 0 6px;
  }

  .panel-order__tabs-panel {
    top: 1px;
  }

  .order-table__title .button .button__text {
    margin-left: 6px;
  }

  @media screen and (max-width: 767px) {
    .tabs__text {
      display: none;
    }

    .tabs__link .zmdi {
      margin-left: 5px;
    }

    .panel-order__tabs-panel {
      left: 50%;
      transform: translate(-50%, -100%);
    }

    .tabs__link {
      padding: 0 4px;
      border-top-left-radius: 3px;
      border-top-right-radius: 0;
    }

    .tabs__link::before {
      margin-right: -1px;
    }

    .tabs__link::after {
      margin-left: -1px;
    }

    .tabs__link .zmdi {
      margin-right: 1px;
    }

    .tabs__counter {
      right: -8px;
      height: 20px;
      min-width: 20px;
    }

    .tabs__counter-text {
      font-size: 12px;
    }

  }
</style>
