import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['profile', 'appSettings']),
    currentCustomer () {
      if (!this.profile) {
        return null
      }

      if (this.appSettings.currentCustomerId) {
        return this.profile.customers.find(
          customer => customer.id === this.appSettings.currentCustomerId
        )
      }

      return this.profile.customers[0]
    }
  }
}
