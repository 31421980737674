<template lang="pug">
  .section-banners(
    v-show="banners.length"
  )
    .container.container--mobile-full.section-banners__container(v-if="!isRefresh && isLoading")
      .js-carousel.carousel-banners
        .swiper-wrapper.carousel-banners__container
          .swiper-slide.carousel-banners__item
            .carousel-banners__card.skeleton
              .skeleton__line.skeleton__line--card
          .swiper-slide.carousel-banners__item
            .carousel-banners__card.skeleton
              .skeleton__line.skeleton__line--card
          .swiper-slide.carousel-banners__item
            .carousel-banners__card.skeleton
              .skeleton__line.skeleton__line--card
        .swiper-scrollbar
    .container.container--mobile-full.section-banners__container(
      ref="container"
      v-if="isRefresh || !isLoading"
    )
      swiper(:options="params" ref="swiper").js-carousel.carousel-banners
        swiper-slide.swiper-slide.carousel-banners__item(
          v-for="banner in banners"
          :key="banner.id"
        )
          a.carousel-banners__card(:href="banner.url" target="_blank")
            img(
              :src="banner.image"
              class="carousel-banners__image",
              :alt="$t('alts.banner')"
            )
        .swiper-scrollbar(
          slot="scrollbar"
        )
</template>

<script>
import { mapGetters } from 'vuex'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

function getParams () {
  return {
    simulateTouch: true,
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 24,
    scrollbar: {
      el: '.swiper-scrollbar',
      hide: false,
      draggable: true
    },
    breakpoints: {
      1024: {
        spaceBetween: 17,
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      768: {
        spaceBetween: 14,
        centeredSlides: true,
        slidesPerView: 'auto',
        slidesPerGroup: 1
      }
    }
  }
}

export default {
  name: 'Banners',
  props: {
    isRefresh: Boolean
  },
  methods: {
    addClassNameToWrapper () {
      if (!this.$refs.container) {
        return
      }
      const swiperWrappers = this.$refs.container.getElementsByClassName('swiper-wrapper')
      if (!swiperWrappers.length) {
        return
      }
      const swiperWrapper = swiperWrappers[0]
      swiperWrapper.classList.add('carousel-banners__container')
    }
  },
  computed: {
    ...mapGetters(['banners', 'bannersLoading', 'profileIsLoading']),
    params () {
      return getParams(this)
    },
    isLoading () {
      return this.bannersLoading || this.profileIsLoading
    }
  },
  watch: {
    isLoading () {
      this.addClassNameToWrapper()
    }
  },
  updated () {
    if (this.$refs.swiper !== undefined && this.$refs.swiper.swiper !== undefined) {
      this.$nextTick(() => {
        setTimeout(() => {
          const event = document.createEvent('HTMLEvents')
          event.initEvent('resize', true, true)
          event.eventType = 'message'
          window.dispatchEvent(event)

          this.$refs.swiper.swiper.slideTo(0, 0)
        }, 500)
      })
    }
  },
  components: {
    swiper,
    swiperSlide
  }
}
</script>

<style scoped>
  .carousel-banners {
    /* overflow: initial; */
    padding-bottom: 24px;
  }

  @media screen and (min-width: 1024px) {
    .carousel-banners .swiper-scrollbar {
      display: block !important;
    }
    .carousel-banners .carousel-banners__item {
      width: auto;
      margin-left: auto;
    }
  }
</style>
