<template lang="pug">
.fixed-top(
  ref="topBar"
  :class="{'fixed-top--with-alert': alertEnabled && alertOpened}"
)
  alert(
    :alertEnabled="alertEnabled"
    @interface="handleFcAfterDateBack"
  )
  app-header(:openedDropdown.sync="openedDropdown")
  banners(
    isRefresh
  )
  progress-bar-loading(v-if="(isNomenclatureLoading || profileIsLoading) && !isRefresh")
  progress-bar(v-if="isRefresh || (!isNomenclatureLoading && !profileIsLoading && progressBarItems.length > 1)")
  .dropdown-overlay(:class="{'dropdown-overlay--show': openedDropdown }" @click="closeDropdown")

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProgressBar from '@/components/ProgressBar.vue'
import Banners from '@/components/Banners.vue'
import Alert from '@/components/Alert.vue'
import AppHeader from '@/components/AppHeader.vue'
import ProgressBarLoading from '@/components/ProgressBarLoading.vue'

export default {
  name: 'TopBar',
  mounted () {
    window.addEventListener('scroll', this.onScrollWindow)
    window.addEventListener('resize', this.calculatePaddingTop)
  },
  props: {
    isRefresh: Boolean
  },
  data () {
    return {
      alertEnabled: false,
      alertOpened: false
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calculatePaddingTop)
  },
  created () {
    this.calculatePaddingTop()
  },
  updated () {
    // this.calculatePaddingTop()
  },
  watch: {
    settings () {
      if (this.settings.length) {
        const notifications = this.settings[0].find(obj => obj.name === 'Notifications')

        // eslint-disable-next-line no-prototype-builtins
        if (notifications && notifications.hasOwnProperty('value') && notifications.value.length) {
          this.alertEnabled = true
          this.alertOpened = true
        }
      }
    }
  },
  methods: {
    ...mapActions(['closeDropdown', 'toggleDropdown']),
    handleFcAfterDateBack (event) {
      this.alertOpened = event
    },
    onScrollWindow () {
      if (this.$refs.topBar !== undefined) {
        if (window.scrollY > 40) {
          this.$refs.topBar.classList.add('fixed-top--compact')
          return
        }
        this.$refs.topBar.classList.remove('fixed-top--compact')

        setTimeout(() => {
          if (!this.$refs.topBar.classList.contains('fixed-top--compact')) {
            this.calculatePaddingTop()
          }
        }, 500)
      }
    },
    calculatePaddingTop () {
      this.$nextTick(() => {
        if (document.querySelector('.wrapper') && this.$refs.topBar) {
          setTimeout(() => {
            document.querySelector('.wrapper').style.paddingTop = `${this.$refs.topBar.offsetHeight + 20}px`
          }, 300)
        }
      })
    }
  },
  computed: {
    ...mapGetters(['settings', 'headerDropdownsState', 'isNomenclatureLoading', 'profileIsLoading', 'progressBarItems']),
    openedDropdown: {
      get () {
        return this.headerDropdownsState
      },
      set (type) {
        this.toggleDropdown(type)
      }
    }
  },
  components: {
    ProgressBarLoading, Alert, AppHeader, Banners, ProgressBar
  }
}
</script>

<style scoped>

</style>
