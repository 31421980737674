import Banners from '@/api/Banners'

const mutations = {
  SET_DATA: 'BANNERS/DATA/SET_DATA',
  SET_LOADING: 'BANNERS/DATA/SET_LOADING',
  CLEAR_DATA: 'BANNERS/DATA/CLEAR_DATA'
}

export default {
  state: {
    data: [],
    isLoading: false
  },
  mutations: {
    [mutations.SET_DATA] (state, payload) {
      state.data = payload
    },
    [mutations.SET_LOADING] (state, payload) {
      state.isLoading = payload
    },
    [mutations.CLEAR_DATA] (state) {
      state.data = []
    }
  },
  actions: {
    async loadBanners (ctx, payload) {
      ctx.commit(mutations.SET_LOADING, true)
      // ctx.commit(mutations.SET_DATA, [])
      try {
        const response = await Banners.init().get(payload)
        ctx.commit(mutations.SET_DATA, response.data)
        return response
      } finally {
        ctx.commit(mutations.SET_LOADING, false)
      }
    },
    clearBanners (ctx) {
      ctx.commit(mutations.CLEAR_DATA)
    }
  },
  getters: {
    banners (state) {
      return state.data
    },
    bannersLoading (state) {
      return state.isLoading
    }
  }
}
