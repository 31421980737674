import { sortBy } from 'lodash'

const mutations = {
  SET_DATA: 'PRICE_TABLE_POPUP/UI/SET_DATA',
  CLEAR_DATA: 'PRICE_TABLE_POPUP/UI/CLEAR_DATA',
  TOGGLE_IS_OPEN: 'PRICE_TABLE_POPUP/UI/TOGGLE_IS_OPEN'
}

export default {
  state: {
    data: [],
    isOpen: false
  },
  mutations: {
    [mutations.SET_DATA] (state, payload) {
      state.data = payload
    },
    [mutations.CLEAR_DATA] (state) {
      state.data = []
    },
    [mutations.TOGGLE_IS_OPEN] (state, payload) {
      state.isOpen = payload
    }
  },
  actions: {
    togglePriceTablePopup (ctx, payload) {
      ctx.dispatch('toggleBodyPopupClass', 'add')
      if (ctx.state.data !== payload) {
        ctx.commit(mutations.SET_DATA, payload)
        ctx.commit(mutations.TOGGLE_IS_OPEN, true)
        return
      }
      ctx.dispatch('closePriceTablePopup')
    },
    closePriceTablePopup (ctx) {
      ctx.dispatch('toggleBodyPopupClass', 'remove')
      ctx.commit(mutations.CLEAR_DATA)
      ctx.commit(mutations.TOGGLE_IS_OPEN, false)
    },
    toggleBodyPopupClass (ctx, payload) {
      const [body] = document.getElementsByTagName('body')
      switch (payload) {
        case 'add':
          body.classList.add('locked')
          break
        case 'remove':
          body.classList.remove('locked')
          break
        default:
      }
    }
  },
  getters: {
    priceTablePopupState (state) {
      return {
        policies: sortBy(state.data.policies, 'products_range'),
        name: state.data.name,
        info: state.data.info,
        banner: state.data.banner
      }
    },
    isOpenPriceTable (state) {
      return state.isOpen
    }
  }
}
