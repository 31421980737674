<template lang="pug">
  div.alert-group(
    v-if="childAlertEnabled && alerts.length"
  )
    div(
      v-for="alert in alerts"
      :key="alert.key"
      v-if="alert.show"
      class="alert-update"
    )
      .container.alert-update__container
        font-icon(name="info")
        .alert-update__message
          span.alert-update__text {{ alert.value }}
          a.alert-update__link(
            v-if="alert.additional"
            :href="alert.additional"
            target="_blank"
          ) {{ $t('buttons.moreInfo') }}
        button(
          @click="closeAlert(alert)"
          type="button"
          class="alert-update__close"
        ): font-icon(name="close")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FontIcon from '@/components/FontIcon.vue'

export default {
  name: 'Alert',
  props: {
    alertEnabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      alerts: [],
      childAlertEnabled: false
    }
  },
  watch: {
    alertEnabled () {
      this.childAlertEnabled = this.alertEnabled
    },
    settings (val) {
      if (val.length) {
        const notifications = val[0].find(obj => obj.name === 'Notifications')

        // eslint-disable-next-line no-prototype-builtins
        if (notifications && notifications.hasOwnProperty('value') && notifications.value.length) {
          this.alerts = []
          notifications.value.forEach((obj) => {
            this.alerts.push({
              ...obj,
              show: true
            })
          })
        }
      }
    }
  },
  beforeMount () {
    this.childAlertEnabled = this.alertEnabled
  },
  computed: {
    ...mapGetters(['settings'])
  },
  methods: {
    ...mapActions(['setLiveSessionState']),
    closeAlert (alert) {
      this.setLiveSessionState(true)
      // eslint-disable-next-line no-param-reassign
      alert.show = false
      const event = document.createEvent('HTMLEvents')
      event.initEvent('resize', true, true)
      event.eventType = 'message'
      window.dispatchEvent(event)

      if (!this.alerts.filter(obj => obj.show).length) {
        this.childAlertEnabled = false
        this.$emit('interface', this.childAlertEnabled)
      }
    }
  },
  components: {
    FontIcon
  }
}
</script>

<style scoped>
  .zmdi-info {
    /* width: 16px; */
    height: 16px;
    margin-top: -1px;
  }

  .zmdi-close {
    font-size: 20px;
  }
</style>
