import axiosInstance from '@/api/axiosInstance'

export default class Alerts {
  plural = '/settings';

  static init () {
    return new Alerts()
  }

  get (params) {
    let str = `${this.plural}`

    if (params) {
      str += params
    }

    return axiosInstance.get(str)
  }
}
