<template lang="pug">
  fragment
    top-bar(
      isRefresh
    )
    .wrapper(
      :class="{'wrapper--without-banners': !banners.length}"
    )
      main.container
        h2.h2.category-products__title(
          v-if="actionItems.length"
        ) {{ specialOffersTitle }}
        products-section(
          v-if="actionItems.length"
          :action="true"
          :data="actionItems"
        )
        category(v-for="productType in shopData" :key="productType.id" :data="productType")
        nomenclature-loading(v-if="isDataLoading && !isRefresh")
      aside-panel
      panel-order(
        isRefresh
      )
      span.version {{ version }}

    choose-store
    .popups
      popup-price-table
      popup
    .panel-order-overlay(
      :class="{'panel-order-overlay--enabled': isOpenedPanelOrder}"
      @click="closePanelOrder()"
    )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import Zendesk from '@dansmaculotte/vue-zendesk'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

import TopBar from '@/components/TopBar.vue'
import Category from '@/components/Category.vue'
import ProductsSection from '@/components/ProductsSection.vue'
import PanelOrder from '@/components/PanelOrder.vue'
import AsidePanel from '@/components/AsidePanel.vue'
import PopupPriceTable from '@/components/PopupPriceTable.vue'
import Popup from '@/components/Popup.vue'
import ChooseStore from '@/components/ChooseStore.vue'
import NomenclatureLoading from '@/components/NomenclatureLoading.vue'
import withPanelOrderControls from '@/mixins/withPanelOrderControls'
import withCurrentCustomer from '@/mixins/withCurrentCustomer'

export default {
  name: 'Home',
  created () {
    this.setVersion()
    this.loadProfile()
    this.loadSettings()
    document.getElementsByTagName('body')[0].className = 'pace-done'

    this.version = localStorage.getItem('version')
    this.pollData()
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  methods: {
    ...mapActions(['loadSettings', 'loadProfile', 'loadShopData', 'togglePanelOrder', 'loadOrders', 'loadDocuments', 'getSubscribes', 'loadBanners']),
    setVersion () {
      const scripts = document.getElementsByTagName('script');
      [].slice.call(scripts).forEach(script => {
        const path = script.src

        if (path.indexOf('/chunk-vendors.') >= 0) {
          const hash = path.substring(path.indexOf('/chunk-vendors.') + 1).split('.')[1]
          localStorage.setItem('version', hash)
        }
      })
    },
    pollData () {
      console.log(`Interval: ${process.env.VUE_APP_INTERVAL_REFRESH_DATA}`)

      if (parseInt(process.env.VUE_APP_INTERVAL_REFRESH_DATA) > 0) {
        this.polling = setInterval(() => {
          console.log('refresh by interval')
          this.isRefresh = true
          this.loadShopData({
            id: this.currentCustomer.id,
            type: 'refresh'
          })

          this.loadSettings()

          if (this.currentPanelOrderTab === 'orders') {
            this.loadOrders(this.currentCustomer.id)
          }
          if (this.currentPanelOrderTab === 'documents') {
            this.loadDocuments(this.currentCustomer.id)
          }
          if (this.currentPanelOrderTab === 'subscribe') {
            this.getSubscribes(this.currentCustomer.id)
          }

          // this.loadBanners(this.currentCustomer.id)
        }, process.env.VUE_APP_INTERVAL_REFRESH_DATA)
      }
    }
  },
  data () {
    return {
      specialOffersTitle: this.$t('category.specialOffers'),
      polling: null,
      isRefresh: false,
      version: ''
    }
  },
  watch: {
    settings () {
      if (this.settings.length) {
        const zendesk = this.settings[0].find(obj => obj.name === 'Zendesk')
        const self = this.settings[0].find(obj => obj.name === 'Self')
        const sentry = this.settings[0].find(obj => obj.name === 'Sentry')

        // eslint-disable-next-line no-prototype-builtins
        if (zendesk && zendesk.hasOwnProperty('value') && zendesk.value.length) {
          const key = zendesk.value.find(obj => obj.key === 'key')

          if (key) {
            localStorage.setItem('zendesk', key.value)

            Vue.use(Zendesk, {
              key: key.value,
              disabled: false,
              hideOnLoad: false,
              settings: {
                webWidget: {
                  color: {
                    theme: '#fff',
                    launcher: '#5f6bf4',
                    launcherText: '#fff',
                    button: '#4e5aae',
                    resultLists: '#5f6bf4',
                    header: '#4e5aae',
                    articleLinks: '#5f6bf4'
                  }
                }
              }
            })
          }
        }

        // eslint-disable-next-line no-prototype-builtins
        if (sentry && sentry.hasOwnProperty('value') && sentry.value.length) {
          const dsn = sentry.value.find(obj => obj.key === 'dsn')

          if (dsn) {
            localStorage.setItem('sentry-dsn', dsn.value)

            Sentry.init({
              Vue,
              // dsn: 'https://2ab93945082b453c8dfea41034f1e69b@o488555.ingest.sentry.io/5549145',
              dsn: dsn.value,
              integrations: [
                new Integrations.BrowserTracing()
              ],
              tracesSampleRate: 1.0,
              environment: process.env.VUE_APP_ENVIRONMENT
            })
          }
        }

        // eslint-disable-next-line no-prototype-builtins
        if (self && self.hasOwnProperty('value') && self.value.length) {
          const name = self.value.find(obj => obj.key === 'Name')

          if (name) {
            this.specialOffersTitle = name.value
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['banners', 'settings', 'nomenclatureAction', 'products', 'shopData', 'isShopDataLoading', 'profileIsLoading', 'isOpenedPanelOrder', 'currentPanelOrderTab']),
    isDataLoading () {
      return this.profileIsLoading || this.isShopDataLoading
    },
    actionItems () {
      if (!this.products.length) {
        return []
      }

      let arr = this.nomenclatureAction.filter(id => this.products.find(obj => obj.id === id))

      arr = arr.map((id) => {
        const product = this.products.find(obj => obj.id === id)
        let productGroup = []

        this.shopData.forEach((data) => {
          productGroup = productGroup.concat(data.productGroups)
        })

        return {
          product,
          group: productGroup.find(obj => obj.id === product.productGroup)
        }
      })

      return arr
    }
  },
  mixins: [
    withPanelOrderControls,
    withCurrentCustomer
  ],
  components: {
    NomenclatureLoading,
    PopupPriceTable,
    TopBar,
    AsidePanel,
    PanelOrder,
    Category,
    ProductsSection,
    Popup,
    ChooseStore
  }
}
</script>

<style>
  .fixed-top+.wrapper.wrapper--without-banners {
    padding-top: 140px;
  }

  .fixed-top--with-alert+.wrapper.wrapper--without-banners {
    padding-top: 190px;
  }

  @media screen and (max-width: 767px) {
    .fixed-top+.wrapper.wrapper--without-banners {
      padding-top: 120px;
    }

    .fixed-top--with-alert+.wrapper.wrapper--without-banners {
      padding-top: 170px;
    }
  }
</style>
