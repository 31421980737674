import axiosInstance from '@/api/axiosInstance'

export default class Banners {
  plural = '/banners';

  static init () {
    return new Banners()
  }

  get (customerId) {
    return axiosInstance.get(`/shop/${customerId}${this.plural}`)
  }
}
