import axiosInstance from '@/api/axiosInstance'

export default class Subscribe {
  plural = '/subscription';

  pluralGet = '/subscriptions';

  static init () {
    return new Subscribe()
  }

  getSubscribes () {
    return axiosInstance.get(`${this.pluralGet}`)
  }

  createSubscribe (params) {
    return axiosInstance.post(`${this.plural}`, params)
  }

  deleteSubscribe (id) {
    return axiosInstance.delete(`${this.plural}/${id}`)
  }
}
