<template lang="pug">
  .empty-state
    .empty-state__container
      .empty-state__label {{$t('messages.emptyCart')}}
      img(
        src="/images/static/lid.svg",
        class="empty-state__image empty-state__image--items",
        :alt="$t('messages.emptyCart')"
      )
      button(type="button" class="button empty-state__button" @click="$emit('closePanel')")
        span.button__text {{$t('buttons.showProducts')}}
</template>

<script>
export default {
  name: 'EmptyContentItems'
}
</script>

<style scoped>

</style>
