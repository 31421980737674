<template lang="pug">
  .table-wrap.table-wrap--order
    table.table.table--order
      thead
        tr.table__row.table__row--main
          th.table__hide-cell {{$t('tables.orders.numberOrBatch')}}
          th(style="width: 150px") {{$t('tables.orders.number')}}
          th.center {{$t('tables.orders.batchNumber')}}
          th {{$t('tables.orders.amount')}}
          th {{$t('tables.orders.status')}}
      tbody
        fragment(
          v-for="order in orders"
          :key="`${order.type}-${order.id}`"
        )
          tr.table__row.table__row--main.table__row--pending(
            :class="{'table__row--clicked': opened.has(order.id)}"
          )
            td.table__hide-cell
              .table__order-number {{order.number}}
              .table__order-batch
              .center(
                v-html="$options.filters.dataOrDash(order, 'batch_number')"
              )
                // icon-button(icon-name="edit", class-name="table__button--edit")
              span.table__button-toggle
            td(@click="toggleRow(order)")
              span.table__button-collapse: font-icon(name="chevron-down")
              span.table__number {{order.number}}
              span.table__number-short {{ order.number | short }}
              span.table__button-toggle
            td.center
              span(
                class="js-edit-cell"
                v-html="$options.filters.dataOrDash(order, 'batch_number')"
              )
              // span(class="table__button--edit js-toggle-edit").button-compact
                font-icon(name="edit", class-name="button-compact__icon")
              span.table__button-toggle
            td
              | {{order.amount}} €
              span.table__button-toggle
            td
              .tablet__flex-container
                .table__button-container(v-if="order.type === 'order'")
                  icon-button(
                    :isLoading="ordersPdfDownloading.has(order.id)"
                    icon-name="download"
                    @click="downloadPdf(order)"
                  )
                label-status(
                  v-else
                  :color="order.status | statusColor"
                ) {{order.status}}
              span.table__button-toggle
          // mobile
          tr.table__row.table__row--mobile(:class="{'table__row--open': opened.has(order.id)}")
            td(colspan="9")
              .custom-table
                .custom-table__row
                  .custom-table__cell {{$t('tables.orders.number')}}
                  .custom-table__cell {{order.number}}
                //- .custom-table__row
                //-   .custom-table__cell {{$t('tables.orders.batchNumber')}}
                //-   .custom-table__cell
                //-     span(
                //-       class="js-edit-cell"
                //-       v-html="$options.filters.dataOrDash(order, 'batch_number')"
                //-     )
                    // +button-compact-span("edit", "table__button--edit js-toggle-edit")
                .custom-table__row
                  .custom-table__cell {{$t('tables.orders.status')}}
                  .custom-table__cell
                    .tablet__flex-container
                      .table__button-container(v-if="order.type === 'order'")
                        icon-button(
                          :isLoading="ordersPdfDownloading.has(order.id)"
                          icon-name="download"
                          @click="downloadPdf(order)"
                        )
                      label-status(
                        v-else
                        :color="order.status | statusColor"
                      ) {{order.status}}
                  // +button-compact-link("download", "#")
                    // +button-compact-link("download", "#")
                // .custom-table__row
                  .custom-table__cell {{$t('tables.orders.actions')}}
                  .custom-table__cell
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import LabelStatus from '@/components/LabelStatus.vue'
import IconButton from '@/components/IconButton.vue'
import FontIcon from '@/components/FontIcon.vue'
import withCurrentCustomer from '@/mixins/withCurrentCustomer'
import statusColor from '@/filters/statusColor'
import dataOrDash from '@/filters/dataOrDash'

export default {
  name: 'OrdersTable',
  data () {
    return {
      opened: new Set()
    }
  },
  filters: {
    dataOrDash,
    short (data) {
      return data ? data.slice(-6) : ''
    },
    date (data) {
      return moment(data).format('DD/MM/YYYY HH:MM')
    },
    statusColor
  },
  methods: {
    ...mapActions(['setLiveSessionState', 'downloadOrderPdf', 'copyOrder']),
    toggleRow (order) {
      this.setLiveSessionState(true)
      if (this.opened.has(order.id)) {
        this.opened.delete(order.id)
      } else {
        this.opened.add(order.id)
      }

      this.opened = new Set(this.opened)
    },
    downloadPdf (order) {
      this.setLiveSessionState(true)
      return this.downloadOrderPdf({ customer: this.currentCustomer, order })
    },
    copyOrderItem (order) {
      this.setLiveSessionState(true)
      return this.copyOrder({ customer: this.currentCustomer, order })
    }
  },
  computed: {
    ...mapGetters(['orders', 'ordersPdfDownloading', 'ordersCopingLoadings'])
  },
  mixins: [
    withCurrentCustomer
  ],
  components: { FontIcon, IconButton, LabelStatus }
}
</script>

<style scoped>
  table.table--order th,
  table.table--order td {
    text-align: center;
  }

  table.table--order td {
    width: auto !important;
  }

  table.table--order .table__row--main td .tablet__flex-container,
  table.table--order .table__row--main td .table__button-container {
    justify-content: center;
  }

  .table--order .table__row--main th:last-child {
    padding-right: 40px;
  }
</style>
