import Settings from '@/api/Settings'

const mutations = {
  SET_DATA: 'ALERTS/DATA/SET_DATA',
  SET_LOADING: 'ALERTS/DATA/SET_LOADING',
  CLEAR_DATA: 'ALERTS/DATA/CLEAR_DATA'
}

export default {
  state: {
    data: [],
    isLoading: false
  },
  mutations: {
    [mutations.SET_DATA] (state, payload) {
      state.data = payload
    },
    [mutations.SET_LOADING] (state, payload) {
      state.isLoading = payload
    },
    [mutations.CLEAR_DATA] (state) {
      state.data = []
    }
  },
  actions: {
    async loadSettings (ctx, payload) {
      ctx.commit(mutations.SET_LOADING, true)
      ctx.commit(mutations.SET_DATA, [])
      try {
        const response = await Settings.init().get(payload)
        ctx.commit(mutations.SET_DATA, response.data)
        return response
      } finally {
        ctx.commit(mutations.SET_LOADING, false)
      }
    },
    clearSettings (ctx) {
      ctx.commit(mutations.CLEAR_DATA)
    }
  },
  getters: {
    settings (state) {
      return state.data
    },
    settingsLoading (state) {
      return state.isLoading
    }
  }
}
