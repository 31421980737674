import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions(['setLiveSessionState', 'togglePanelOrder']),
    closePanelOrder () {
      this.setLiveSessionState(true)
      this.togglePanelOrder(false)
      this.toggleBodyClass('close')

      if (window.scrollY > 40) {
        return
      }
      this.toggleFixedTopClass('remove')
    },
    toggleBodyClass (type) {
      this.setLiveSessionState(true)
      const body = document.getElementsByTagName('body')[0]
      // eslint-disable-next-line default-case
      switch (type) {
        case 'open':
          body.classList.add('panel-order-opened')
          break
        case 'close':
          body.classList.remove('panel-order-opened')
          break
      }
    },
    toggleFixedTopClass (type) {
      this.setLiveSessionState(true)
      const fixedTop = document.getElementsByClassName('fixed-top')[0]
      // eslint-disable-next-line default-case
      switch (type) {
        case 'add':
          fixedTop.classList.add('fixed-top--compact')
          break
        case 'remove':
          fixedTop.classList.remove('fixed-top--compact')
          break
      }
    }
  }
}
