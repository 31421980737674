<template lang="pug">
  .table-wrap.table-wrap--subscribe
    table.table.table--subscribe
      thead
        tr.table__row.table__row--main
          th
          th() {{$t('tables.subscribe.item')}}
          th.center(style="width: 200px") {{$t('tables.subscribe.status')}}
          th(style="width: 200px") {{$t('tables.subscribe.subscribed')}}
          th {{$t('tables.subscribe.edit')}}
      tbody
        fragment(
          v-for="subscribe in subscribes.data"
          :key="subscribe.id"
        )
          tr.table__row
            td
              .table__order-image
                img(
                  :src="subscribe.image"
                  :alt="subscribe.product"
                )
            td {{ subscribe.product }}
            td
              .tablet__flex-container.table--subscribe__flex-center(
                v-if="subscribe.status"
              )
                label-status(color="blue") {{ subscribe.status }}
              span.table__button-toggle
            td {{ subscribe.subscribed }}
            td
              icon-button(
                icon-name="delete"
                @click="deleteItem(subscribe.id)"
              )
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import LabelStatus from '@/components/LabelStatus.vue'
import IconButton from '@/components/IconButton.vue'
import FontIcon from '@/components/FontIcon.vue'
import withCurrentCustomer from '@/mixins/withCurrentCustomer'
import withBasketItems from '@/mixins/withBasketItems'
import statusColor from '@/filters/statusColor'

export default {
  name: 'SubscribeTable',
  filters: {
    date (data) {
      return moment(data).format('DD/MM/YYYY HH:MM')
    },
    statusColor
  },
  methods: {
    ...mapActions(['setLiveSessionState', 'removeSubscribe', 'getSubscribes']),
    async deleteItem (id) {
      this.setLiveSessionState(true)
      await this.removeSubscribe(id)
      await this.getSubscribes()
    }
  },
  computed: {
    ...mapGetters(['subscribes'])
  },
  mixins: [
    withBasketItems,
    withCurrentCustomer
  ],
  components: { FontIcon, IconButton, LabelStatus }
}
</script>

<style scoped>
  table.table--subscribe th,
  table.table--subscribe td {
    text-align: center;
  }

  table.table--subscribe th:first-child,
  table.table--subscribe td:first-child {
    /* padding-left: 45px; */
    text-align: left;
    width: 80px;
  }

  table.table--subscribe th:nth-child(2),
  table.table--subscribe td:nth-child(2) {
    text-align: left;
  }

  table.table--subscribe th:last-child,
  table.table--subscribe td:last-child {
    padding-right: 15px;
  }

  table.table--subscribe td {
    width: auto !important;
  }

  .table--subscribe__flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 767px) {
    table.table--subscribe th[data-v-65fcc8d4]:first-child,
    table.table--subscribe td[data-v-65fcc8d4]:first-child {
      padding-left: 10px;
    }

    table.table--subscribe td, table.table--subscribe th {
      width: auto !important;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
</style>
