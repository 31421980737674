<template lang="pug">
  span.badge
    font-icon(:name="iconParams.iconName" :style-name="iconParams.style")
    span.badge__text {{ iconParams.text }}
</template>

<script>

const icons = {
  new: {
    iconName: 'fire',
    style: { color: '#F13C5B' },
    text: 'New product!'
  },
  freeShipping: {
    iconName: 'truck',
    style: { color: '#4654EF' },
    text: 'Free shipping'
  },
  hit: {
    iconName: 'polygon',
    style: { color: '#F8C400' },
    text: 'HIT'
  }
}

const iconNames = Object.keys(icons)

export default {
  name: 'BadgedIcon',
  props: {
    name: {
      type: String,
      validator: value => iconNames.includes(value)
    }
  },
  computed: {
    iconParams () {
      return icons[this.name] || {}
    }
  }
}
</script>

<style scoped>

</style>
