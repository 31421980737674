import {
  filter, sortBy, findLast, get
} from 'lodash'
import currency from 'currency.js'
import { mapGetters } from 'vuex'
import withCurrentCustomer from '@/mixins/withCurrentCustomer'

export default {
  mixins: [
    withCurrentCustomer
  ],
  computed: {
    ...mapGetters(['basket', 'products', 'productGroups', 'pricingPolicies', 'profile']),
    /**
     * basketItems
     */

    productsObject () {
      return this.products.reduce(this.objReducer, {})
    },
    groupsObject () {
      return this.productGroups
        .map(item => ({
          ...item,
          prices: sortBy(
            filter(this.pricingPolicies, { product_group_id: item.id }),
            ['products_range']
          )
        }))
        .reduce(this.objReducer, {})
    },

    basketItems () {
      const basketItems = this.basket
        .map((basketItem, index) => ({
          ...basketItem,
          index,
          product: this.productsObject[basketItem.productId],
          group: this.groupsObject[basketItem.groupId]
        }))
        .filter((basketItem) => {
          if (!this.profile || !this.currentCustomer) {
            return false
          }

          return basketItem.customerId === this.currentCustomer.id &&
            basketItem.profileId === this.profile.id &&
            basketItem.product &&
            basketItem.group
        })

      const allProductsCount = basketItems.reduce((acc, item) => acc + item.count, 0)

      return basketItems.map((item) => {
        let priceObj = findLast(item.group.prices, o => o.products_range <= allProductsCount)
        if (!priceObj) {
          priceObj = get(item, 'group.prices.0', {})
        }

        const percent = (item.group.vat / 100).toString()

        const discountPrice = item.product.discount_price
        const price = discountPrice !== null ? discountPrice : priceObj.price
        const vatPrice = currency(price).add(currency(price).multiply(percent))

        const quantity = item.count * item.group.sales_unit_volume

        const totalPrice = currency(price).multiply(quantity)
        const totalVatPrice = currency(totalPrice).add(currency(totalPrice).multiply(percent))

        const prices = {
          price,
          vatPrice,

          quantity,

          totalPrice,
          totalVatPrice
        }

        return { ...item, prices }
      })
    },
    /**
     * end basketItems
     */

    /**
     * deposits
     */
    deposits () {
      const deposits = this.generateDeposits(this.depositsObject)
      return sortBy(deposits, ['vat', 'price'])
    },
    depositsObject () {
      return this.basketItems
        .filter(basketItem => basketItem.product.deposit_enabled)
        .reduce((acc, basketItem) => {
          const key = this.generateDepositKey(basketItem)

          if (acc[key]) {
            acc[key].push(basketItem)
          } else {
            acc[key] = [basketItem]
          }

          return acc
        }, {})
    },
    /**
     * end deposits
     */

    /**
     * total
     */
    totalAmount () {
      return this.basketItems
        .reduce((acc, basketItem) => acc + basketItem.count, 0)
    },
    totalWithoutVAT () {
      const totalPrice = this.basketItems
        .reduce((acc, basketItem) => currency(acc).add(basketItem.prices.totalPrice.value), 0)

      const totalDeposits = this.deposits
        .reduce((acc, depositItem) => currency(acc).add(depositItem.totalPrice.value), 0)

      return currency(totalPrice).add(totalDeposits)
    },
    totalVATObject () {
      const totalVATObject = {}

      this.basketItems.forEach((basketItem) => {
        const { vat } = basketItem.group
        const { prices } = basketItem
        const priceDiff = currency(prices.totalVatPrice).subtract(prices.totalPrice)

        if (Object.keys(totalVATObject).indexOf(vat.toString()) < 0) {
          totalVATObject[vat] = currency(priceDiff)
        } else {
          totalVATObject[vat] = currency(totalVATObject[vat]).add(priceDiff)
        }
      })

      this.deposits.forEach((depositItem) => {
        const { vat } = depositItem
        const priceDiff = currency(depositItem.totalVatPrice).subtract(depositItem.totalPrice)

        if (Object.keys(totalVATObject).indexOf(vat.toString()) < 0) {
          totalVATObject[vat] = currency(priceDiff)
        } else {
          totalVATObject[vat] = currency(totalVATObject[vat]).add(priceDiff)
        }
      })

      return totalVATObject
    },
    totalWithVAT () {
      const totalPrice = this.basketItems
        .reduce((acc, basketItem) => currency(acc).add(basketItem.prices.totalVatPrice.value), 0)

      const totalDeposits = this.deposits
        .reduce((acc, depositItem) => currency(acc).add(depositItem.totalVatPrice.value), 0)

      return currency(totalPrice).add(totalDeposits)
    }
    /**
     * end total
     */
  },
  methods: {
    objReducer: (acc, item) => ({ ...acc, [item.id]: item }),
    generateDepositKey (basketItem) {
      const keyArray = []
      if (basketItem.product.deposit_vat) {
        keyArray.push(`depositVat: ${basketItem.product.deposit_vat}`)
      }
      if (basketItem.product.deposit_price) {
        keyArray.push(`depositPrice: ${basketItem.product.deposit_price}`)
      }

      return keyArray.join(', ')
    },
    generateDeposits (depositsObj) {
      const defaultDepositsObj = {
        vat: 0,
        price: 0,
        vatPrice: 0,
        quantity: 0,
        totalPrice: 0,
        totalVatPrice: 0
      }

      return Object.values(depositsObj)
        .reduce((depositAcc, products) => {
          const deposit = products.reduce((acc, basketItem) => {
            const vat = basketItem.product.deposit_vat
            const percent = vat / 100

            let { price, vatPrice } = acc
            if (!acc.price || !acc.vatPrice) {
              price = basketItem.product.deposit_price
              vatPrice = currency(price).add(currency(price).multiply(percent))
            }

            const quantity = acc.quantity + basketItem.prices.quantity
            const totalPrice = currency(acc.totalPrice).add(
              currency(price * basketItem.prices.quantity)
            )
            const totalVatPrice = currency(acc.totalVatPrice).add(
              currency(vatPrice * basketItem.prices.quantity)
            )

            return {
              vat,
              price,
              vatPrice,
              quantity,
              totalPrice,
              totalVatPrice
            }
          }, defaultDepositsObj)

          return [...depositAcc, deposit]
        }, [])
    }
  }
}
