import Shop from '@/api/Shop'

const mutations = {
  SET_DATA: 'DOCUMENTS/DATA/SET_DATA',
  SET_LOADING: 'DOCUMENTS/DATA/SET_LOADING',
  CLEAR_DATA: 'DOCUMENTS/DATA/CLEAR_DATA',
  START_DOWNLOADING_WAYBILL_PDF: 'DOCUMENTS/DATA/START_DOWNLOADING_WAYBILL_PDF',
  STOP_DOWNLOADING_WAYBILL_PDF: 'DOCUMENTS/DATA/STOP_DOWNLOADING_WAYBILL_PDF',
  START_DOWNLOADING_INVOICE_PDF: 'DOCUMENTS/DATA/START_DOWNLOADING_INVOICE_PDF',
  STOP_DOWNLOADING_INVOICE_PDF: 'DOCUMENTS/DATA/STOP_DOWNLOADING_INVOICE_PDF'
}

export default {
  state: {
    data: [],
    isLoading: false,
    pdfWaybillDownloading: new Set(),
    pdfInvoiceDownloading: new Set()
  },
  mutations: {
    [mutations.SET_DATA] (state, payload) {
      state.data = payload
    },
    [mutations.SET_LOADING] (state, payload) {
      state.isLoading = payload
    },
    [mutations.CLEAR_DATA] (state) {
      state.data = []
    },
    [mutations.START_DOWNLOADING_WAYBILL_PDF] (state, payload) {
      const set = state.pdfWaybillDownloading
      set.add(payload)
      state.pdfWaybillDownloading = new Set(set)
    },
    [mutations.STOP_DOWNLOADING_WAYBILL_PDF] (state, payload) {
      const set = state.pdfWaybillDownloading
      set.delete(payload)
      state.pdfWaybillDownloading = new Set(set)
    },
    [mutations.START_DOWNLOADING_INVOICE_PDF] (state, payload) {
      const set = state.pdfInvoiceDownloading
      set.add(payload)
      state.pdfInvoiceDownloading = new Set(set)
    },
    [mutations.STOP_DOWNLOADING_INVOICE_PDF] (state, payload) {
      const set = state.pdfInvoiceDownloading
      set.delete(payload)
      state.pdfInvoiceDownloading = new Set(set)
    }
  },
  actions: {
    async loadDocuments (ctx, payload) {
      ctx.commit(mutations.SET_LOADING, true)
      try {
        const response = await Shop.init().getDocuments(payload)
        ctx.commit(mutations.SET_DATA, response.data)
      } finally {
        ctx.commit(mutations.SET_LOADING, false)
      }
    },
    async downloadWaybillPdf (ctx, payload) {
      ctx.commit(mutations.START_DOWNLOADING_WAYBILL_PDF, payload.document.id)
      try {
        const fileName = `${payload.document.number}-waybill.pdf`
        return await Shop.init()
          .downloadDocumentsWaybillPdf(payload.customer.id, payload.document.id, fileName)
      } finally {
        ctx.commit(mutations.STOP_DOWNLOADING_WAYBILL_PDF, payload.document.id)
      }
    },
    async downloadInvoicePdf (ctx, payload) {
      ctx.commit(mutations.START_DOWNLOADING_INVOICE_PDF, payload.document.id)
      try {
        const fileName = `${payload.document.number}-invoice.pdf`
        return await Shop.init()
          .downloadDocumentsInvoicePdf(payload.customer.id, payload.document.id, fileName)
      } finally {
        ctx.commit(mutations.STOP_DOWNLOADING_INVOICE_PDF, payload.document.id)
      }
    }
  },
  getters: {
    isDocumentsLoading (state) {
      return state.isLoading
    },
    documents (state) {
      return state.data
    },
    documentsWaybillPdfDownloading (state) {
      return state.pdfWaybillDownloading
    },
    documentsInvoicePdfDownloading (state) {
      return state.pdfInvoiceDownloading
    }
  }
}
