import axiosInstance from '@/api/axiosInstance'

export default class Shop {
  plural = '/shop';

  static init () {
    return new Shop()
  }

  loadNomenclature (customerId, params) {
    return axiosInstance.get(`${this.plural}/${customerId}/nomenclature`, { params })
  }

  loadProductTypes (customerId, params) {
    return axiosInstance.get(`${this.plural}/${customerId}/product-types`, { params })
  }

  loadProductGroups (customerId, params) {
    return axiosInstance.get(`${this.plural}/${customerId}/product-groups`, { params })
  }

  loadProductGroup (customerId, productGroupId, params) {
    return axiosInstance.get(`${this.plural}/${customerId}/product-group/${productGroupId}/info`, params)
  }

  loadProducts (customerId, params) {
    return axiosInstance.get(`${this.plural}/${customerId}/products`, { params })
  }

  loadPricingPolicies (customerId, params) {
    return axiosInstance.get(`${this.plural}/${customerId}/pricing-policies`, { params })
  }

  loadOrders (customerId) {
    return axiosInstance.get(`${this.plural}/${customerId}/orders`)
  }

  copyOrder (customerId, orderId) {
    return axiosInstance.post(`${this.plural}/${customerId}/orders/${orderId}/copy`)
  }

  createPreOrder (customerId, data) {
    return axiosInstance.post(`${this.plural}/${customerId}/pre-order`, data)
  }

  getDocuments (customerId) {
    return axiosInstance.get(`${this.plural}/${customerId}/documents`)
  }

  downloadOrderPdf (customerId, orderId, filename) {
    return axiosInstance
      .get(`${this.plural}/${customerId}/orders/${orderId}/pdf`, {
        responseType: 'blob'
      })
      .then(response => this.downloadPdfCb(response, filename))
  }

  downloadDocumentsWaybillPdf (customerId, shipmentId, filename) {
    return axiosInstance
      .get(`${this.plural}/${customerId}/documents/${shipmentId}/waybill/pdf`, {
        responseType: 'blob'
      })
      .then(response => this.downloadPdfCb(response, filename))
  }

  downloadDocumentsInvoicePdf (customerId, shipmentId, filename) {
    return axiosInstance
      .get(`${this.plural}/${customerId}/documents/${shipmentId}/invoice/pdf`, {
        responseType: 'blob'
      })
      .then(response => this.downloadPdfCb(response, filename))
  }

  // eslint-disable-next-line class-methods-use-this
  downloadPdfCb (response, filename) {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename || 'file.pdf')
    document.body.appendChild(link)
    link.click()
    return response
  }
}
