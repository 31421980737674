<template lang="pug">
  button.button-compact(
    :class="[className, { 'button--is-loading': isLoading, disabled }]"
    type="button"
    @click="onClick"
    :disabled="isLoading || disabled"
  )
     font-icon(v-if="!isLoading" :name="iconName" class="button-compact__icon")
</template>

<script>
import FontIcon from '@/components/FontIcon.vue'

export default {
  name: 'IconButton',
  props: {
    iconName: String,
    className: String,
    isLoading: Boolean,
    disabled: Boolean
  },
  methods: {
    onClick (e) {
      this.$emit('click', e)
    }
  },
  components: {
    FontIcon
  }
}
</script>

<style lang="scss">
  button.button-compact.disabled {
    color: #ccc;
    cursor: initial;
  }

  .button--is-loading {
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 16px;
      height: 16px;
      background: url("/images/static/blue-loader.svg") no-repeat;
      fill: blue;
      animation: spin .7s linear infinite;
    }
  }
</style>
