<template lang="pug">
  section.category-products__section
    h3.h3.category-products__subtitle(
      v-if="!action"
    ) {{data.name}}
      span.category-products__pricing(
        v-if="!action"
        @click="getInfoProductsSection()"
      )
        | {{$t('tables.titles.pricing')}}
    .category-products__list.category-products__list--action(
      v-if="action"
    )
      .product-card-group(
        v-for="(array, i) in actionGrouped"
        :key="i"
      )
        product-card(
          v-for="(data, j) in array"
          :key="j"
          :data="{ product: data.product, group: data.group, action}"
        )
    .category-products__list(
      v-else
    )
      product-card(
        v-for="product in data.products"
        :key="product.id"
        :data="{ product, group: data }"
      )
</template>

<script>
import { mapActions } from 'vuex'
import ProductCard from '@/components/ProductCard.vue'

export default {
  name: 'ProductsSection',
  props: {
    data: [Object, Array],
    action: Boolean
  },
  data () {
    return {
      countInRow: 6,
      dataInfo: {
        info: '',
        banner: '',
        image: ''
      }
    }
  },
  methods: {
    ...mapActions(['togglePriceTablePopup', 'loadProductGroup', 'loadProductGroups']),
    async getInfoProductsSection () {
      const response = await this.loadProductGroup({
        customer: this.data.pricingPolicies[0].customer_id,
        product: this.data.id
      })
      this.dataInfo = response.data
      this.togglePriceTablePopup(this.priceTable)
    },
    calcCountInRow () {
      // eslint-disable-next-line max-len
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

      if (width >= 1240) {
        this.countInRow = 6
      }

      if (width < 1240 && width >= 1024) {
        this.countInRow = 5
      }

      if (width < 1024 && width >= 768) {
        this.countInRow = 4
      }

      if (width < 768 && width >= 601) {
        this.countInRow = 3
      }

      if (width < 601) {
        this.countInRow = 2
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.calcCountInRow)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calcCountInRow)
  },
  computed: {
    priceTable () {
      return {
        policies: this.data.pricingPolicies,
        info: this.dataInfo.info,
        banner: this.dataInfo.banner,
        name: this.data.name
      }
    },
    actionGrouped () {
      const R = []
      // eslint-disable-next-line max-len
      for (let i = 0; i < this.data.length; i += this.countInRow) R.push(this.data.slice(i, i + this.countInRow))

      return R
    }
  },
  components: { ProductCard }
}
</script>

<style scoped>
</style>
