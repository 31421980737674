import Subscribe from '@/api/Subscribe'

const mutations = {
  SET_DATA: 'SUBSCRIBES/DATA/SET_DATA',
  SET_LOADING: 'SUBSCRIBES/DATA/SET_LOADING',
  SET_CREATING: 'SUBSCRIBES/DATA/SET_CREATING',
  SET_REMOVING: 'SUBSCRIBES/DATA/SET_REMOVING',
  CLEAR_DATA: 'SUBSCRIBES/DATA/CLEAR_DATA'
}

export default {
  state: {
    data: [],
    isLoading: false,
    isCreating: false,
    isRemoving: false
  },
  mutations: {
    [mutations.SET_DATA] (state, payload) {
      state.data = payload
    },
    [mutations.SET_LOADING] (state, payload) {
      state.isLoading = payload
    },
    [mutations.SET_CREATING] (state, payload) {
      state.isCreating = payload
    },
    [mutations.SET_REMOVING] (state, payload) {
      state.isRemoving = payload
    },
    [mutations.CLEAR_DATA] (state) {
      state.data = []
    }
  },
  actions: {
    async getSubscribes (ctx, payload) {
      ctx.commit(mutations.SET_LOADING, true)
      try {
        const response = await Subscribe.init().getSubscribes(payload)
        ctx.commit(mutations.SET_DATA, response.data)
        return response
      } finally {
        ctx.commit(mutations.SET_LOADING, false)
      }
    },

    async removeSubscribe (ctx, payload) {
      ctx.commit(mutations.SET_REMOVING, true)
      try {
        const response = await Subscribe.init().deleteSubscribe(payload)
        return response
      } finally {
        ctx.commit(mutations.SET_REMOVING, false)
      }
    },

    async createSubscribe (ctx, payload) {
      ctx.commit(mutations.SET_CREATING, true)
      try {
        return await Subscribe.init().createSubscribe(payload)
      } finally {
        ctx.commit(mutations.SET_CREATING, false)
      }
    }
  },
  getters: {
    subscribes (state) {
      return state.data
    },
    isSubscribesLoading (state) {
      return state.isLoading
    },
    isSubscribesCreating (state) {
      return state.isCreating
    },
    isSubscribesRemoving (state) {
      return state.isRemoving
    }
  }
}
