import axios from 'axios'
import { get } from 'lodash'

const baseURL = process.env.VUE_APP_API_URL
const axiosInstance = axios.create({
  baseURL,
  timeout: '10000'
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    const version = localStorage.getItem('version')
    const sentryDSN = localStorage.getItem('sentry-dsn')
    const zendesk = localStorage.getItem('zendesk')

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.authorization = `Bearer ${token}`
    }
    if (version) {
      // eslint-disable-next-line no-param-reassign
      config.headers['X-GTP-Version'] = version
    }
    if (sentryDSN) {
      // eslint-disable-next-line no-param-reassign
      config.headers['X-GTP-Sentry'] = sentryDSN
    }
    if (zendesk) {
      // eslint-disable-next-line no-param-reassign
      config.headers['X-GTP-Zendesk'] = zendesk
    }
    return config
  },
  error => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  config => config,
  (config) => {
    if (config.isAxiosError) {
      const status = get(config, 'response.status')

      const isLoginPage = window.location.pathname.includes('/login')
      if ((status === 401 || status === 403) && !isLoginPage) {
        window.location.href = '/login'
      }
      return Promise.reject(config)
    }

    if (!config.response) {
      return config
    }

    if (config.response.status > 201) {
      throw config
    }
    return config
  }
)

export default axiosInstance
