<template lang="pug">
  section#category-drinks.category-products
    .category-products__title.skeleton
      .skeleton__line.skeleton__line--size-m
    // section
    section.category-products__section
      .category-products__subtitle.skeleton
        .skeleton__line.skeleton__line--size-m
      .category-products__list
        .product-card.product-card--loading.category-products__item(v-for="i in 6" :key="i")
          .product-card__preview.product-card__preview--loading
          .product-card__title.product-card__title--loading
    // section
    section.category-products__section
      .category-products__subtitle.skeleton
        .skeleton__line.skeleton__line--size-m
      .category-products__list
        .product-card.product-card--loading.category-products__item(v-for="i in 6" :key="i")
          .product-card__preview.product-card__preview--loading
          .product-card__title.product-card__title--loading
</template>

<script>
export default {
  name: 'NomenclatureLoading'
}
</script>

<style scoped>

</style>
