<template lang="pug">
fragment
  .table-wrap
    table.table.table--items
      thead
        tr
          th
          th {{$t('tables.items.item')}}
          th {{$t('tables.items.amount')}}
          th {{$t('tables.items.units')}}
          th {{$t('tables.items.productPrice')}}
          th {{$t('tables.items.vat')}}
          th {{$t('tables.items.taxablePrice')}}
          th {{$t('tables.items.total')}}
          th {{$t('tables.items.taxableTotal')}}
          th {{$t('tables.items.edit')}}
      tbody
        fragment(
          v-for="item in basketItems"
          :key="item.index"
        )
          tr(
            :class="{'table__row--clicked': opened.has(`item-${item.index}`)}"
          )
            td.toogle-row.js-button-panel(@click="toggleRow(`item-${item.index}`)")
              span.table__button-collapse: font-icon(name="chevron-down")
              .table__order-image
                img(
                  :src="item | src"
                  :alt="item.product.name"
                )
            td.js-button-panel
              div.table__item-title {{item.product.name}}
              div.table__item-count {{ getDescription(item) }}
            td
              number-picker(
                :value="item.count"
                @change="(newValue) => updateCount(item.index, newValue)"
                :step="item | step"
              )
            td {{item.prices.quantity}}
            td {{item.prices.price}} €
            td {{item.group.vat}}
            td {{item.prices.vatPrice}} €
            td {{item.prices.totalPrice}} €
            td {{item.prices.totalVatPrice}} €
            td
              icon-button(
                icon-name="delete"
                @click="deleteItem(item.index)"
              )
          // mobile
          tr.table__row.table__row--mobile(:class="{'table__row--open': opened.has(`item-${item.index}`)}")
            td(colspan="9")
              .custom-table
                .custom-table__row
                  .custom-table__cell {{$t('tables.items.units')}}
                  .custom-table__cell {{item.prices.quantity}}
                .custom-table__row
                  .custom-table__cell {{$t('tables.items.productPrice')}}
                  .custom-table__cell {{item.prices.price}} €
                .custom-table__row
                  .custom-table__cell {{$t('tables.items.vat')}}
                  .custom-table__cell {{item.group.vat}}
                .custom-table__row
                  .custom-table__cell {{$t('tables.items.taxablePrice')}}
                  .custom-table__cell {{item.prices.vatPrice}} €
                .custom-table__row
                  .custom-table__cell {{$t('tables.items.total')}}
                  .custom-table__cell {{item.prices.totalPrice}} €
                .custom-table__row
                  .custom-table__cell {{$t('tables.items.taxableTotal')}}
                  .custom-table__cell {{item.prices.totalVatPrice}} €
      fragment(
        v-for="(deposit, i) in deposits"
        :key="i"
      )
        tr(
          :class="{'table__row--clicked': opened.has(`deposit-${i}`)}"
        )
          td.toogle-row(@click="toggleRow(`deposit-${i}`)")
            span.table__button-collapse: font-icon(name="chevron-down")
          td
            div.table__item-title(
              v-if="i === 0"
            ) {{$t('tables.items.deposit')}}
          td
          td {{deposit.quantity}}
          td {{deposit.price}} €
          td {{deposit.vat}}
          td {{deposit.vatPrice}} €
          td {{deposit.totalPrice}} €
          td {{deposit.totalVatPrice}} €
          td
        // mobile
        tr.table__row.table__row--mobile(:class="{'table__row--open': opened.has(`deposit-${i}`)}")
          td(colspan="9")
            .custom-table
              .custom-table__row
                .custom-table__cell {{$t('tables.items.units')}}
                .custom-table__cell {{deposit.quantity}}
              .custom-table__row
                .custom-table__cell {{$t('tables.items.productPrice')}}
                .custom-table__cell {{deposit.price}}
              .custom-table__row
                .custom-table__cell {{$t('tables.items.vat')}}
                .custom-table__cell {{deposit.vat}}
              .custom-table__row
                .custom-table__cell {{$t('tables.items.taxablePrice')}}
                .custom-table__cell {{deposit.vatPrice}}
              .custom-table__row
                .custom-table__cell {{$t('tables.items.total')}}
                .custom-table__cell {{deposit.totalPrice}}
              .custom-table__row
                .custom-table__cell {{$t('tables.items.taxableTotal')}}
                .custom-table__cell {{deposit.totalVatPrice}}
      tr.vat-row(v-if="totalWithoutVAT")
        td
        td
          div.table__item-title {{$t('tables.items.totalWithoutVAT')}}
        td
        td
        td
        td
        td
        td
        td {{totalWithoutVAT}} €
        td
      tr.vat-row(v-for="(vat, i) in Object.keys(totalVATObject)")
        td
        td
          div.table__item-title {{$t('tables.items.totalVAT')}} {{ vat }}%
        td
        td
        td
        td
        td
        td
        td {{totalVATObject[vat]}} €
        td
      tr.vat-row(v-if="totalWithVAT")
        td
        td
          div.table__item-title {{$t('tables.items.totalWithVAT')}}
        td
        td
        td
        td
        td
        td
        td {{totalWithVAT}} €
        td
  .order-table__footer
    .order-table__column
      //- controls
      .order-table__controls
        //- button
        button(
          :class="{'button--is-loading': isOrdersCreating}"
          type="button"
          class="button"
          @click="createOrder"
          :disabled="isDisabledSaveAndCheckout"
        )
          span.button__text {{$t('buttons.сheckout')}}
        //- comment
        .order-table__comment.comment-order
          button(
            type="button"
            :class="commentButtonClassNames"
          )
            span.button__text( @click="isOpenMessage = true" ) {{$t('buttons.orderComment')}}
            // +icon("chevron-down", "button__icon")
          .comment-order__content.js-collapse-content(
            :class="{ 'js-collapse-content--show': isOpenMessage || message }"
          )
            textarea(
              v-model="message"
              class="form-textarea comment-order__textarea"
              :placeholder="$t('placeholders.itemsComment')"
              rows="2"
            )
</template>

<script>
import { get } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import FontIcon from '@/components/FontIcon.vue'
import withBasketItems from '@/mixins/withBasketItems'
import NumberPicker from '@/components/NumberPicker.vue'
import IconButton from '@/components/IconButton.vue'
import withCurrentCustomer from '@/mixins/withCurrentCustomer'

export default {
  name: 'ItemsTable',
  data () {
    return {
      isOpenMessage: false,
      opened: new Set()
    }
  },
  mixins: [
    withBasketItems,
    withCurrentCustomer
  ],
  filters: {
    units (data) {
      return data.count * data.group.sales_unit_volume
    },

    src (data) {
      return get(data, 'product.product_image')
    },

    packWeight (data) {
      const productsCount = data.group.sales_unit_volume / data.product.number_in_package
      return productsCount * data.product.weight
    },

    productsCount (data) {
      return data.group.sales_unit_volume / data.product.number_in_package
    },

    step (data) {
      const productsCount = data.group.sales_unit_volume / data.product.number_in_package
      return 1 / productsCount
    }
  },
  computed: {
    ...mapGetters(['isOrdersCreating', 'basketMessage', 'progressBarItems']),
    message: {
      get () {
        return this.basketMessage
      },
      set (newValue) {
        this.setBasketMessage(newValue)
      }
    },
    allSlotsCount () {
      return this.basketItems.reduce((acc, item) => acc + item.count, 0)
    },
    isDisabledSaveAndCheckout () {
      const minLen = this.progressBarItems[1] || 7
      return this.allSlotsCount < minLen || this.isOrdersCreating
    },
    commentButtonClassNames () {
      return [
        'button',
        'button--transparent',
        'js-collapse-button',
        'comment-order__button',
        { 'js-collapse-button--active': this.isOpenMessage || this.message }
      ]
    }
  },
  methods: {
    ...mapActions(['setLiveSessionState', 'updateBasketItemCount', 'removeItemFromBasket', 'saveBasket', 'createPreOrder', 'clearBasket', 'loadOrders', 'openPopup', 'setBasketMessage']),
    toggleRow (indexKey) {
      this.setLiveSessionState(true)
      if (this.opened.has(indexKey)) {
        this.opened.delete(indexKey)
      } else {
        this.opened.add(indexKey)
      }

      this.opened = new Set(this.opened)
    },
    getDescription (data) {
      const productsCount = data.group.sales_unit_volume / data.product.number_in_package
      const description = `1 ${this.$t('units.me').toUpperCase()} = ${productsCount} x ${data.product.number_in_package}-${this.$t('units.pack')}`

      if (!data.product.deposit_enabled) {
        return description
      }

      return [
        description,
        this.$t('units.palpa').toUpperCase()
      ].join(' / ')
    },
    updateCount (index, count) {
      this.setLiveSessionState(true)
      this.updateBasketItemCount({ index, count })
    },
    deleteItem (index) {
      this.setLiveSessionState(true)
      this.removeItemFromBasket(index)
    },
    async createOrder () {
      this.setLiveSessionState(true)
      const data = {
        pre_order_items: this.basketItems.map(item => ({
          product_id: item.product.id,
          quantity: item.count.toString()
        })),
        comment: this.message.length ? this.message : undefined
        // number
        // reference_number
      }
      await this.createPreOrder({ customer: this.currentCustomer, data })
      this.openPopup({
        title: this.$t('modals.titles.success'),
        message: this.$t('modals.messages.orderCreated')
      })
      this.clearBasket()
      await this.loadOrders(this.currentCustomer.id)
    }
  },
  watch: {
    basketItems () {
      this.saveBasket()
    }
  },
  components: { IconButton, NumberPicker, FontIcon }
}
</script>

<style scoped>

</style>
