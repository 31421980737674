<template lang="pug">
  button.product-card.js-button-panel(
    v-if="data.action || !data.action && !product.action"
    @click="onClickProduct"
    class="category-products__item"
    :class="buildProductCardClass(product)"
  )
    span.product-card__count(
      v-if="getProductCardCountInBasket(product)"
    )
      span.product-card__count-text
        | {{ getProductCardCountInBasket(product).count }}
    span.product-card__badges
      span.badge(v-for="tag in product.productTags" :key="tag.id")
        font-icon(:name="tag.icon" :style="{color: tag.color}")
        span.badge__text {{tag.name}}
    span.product-card-icon(
      v-if="buildIcon(product).show"
    )
      img.product-card-icon__image(
        :src="buildIcon(product).image"
      )
      span.product-card-icon__text {{buildIcon(product).text}}
    span.product-card__preview
      img.product-img(
        width="112"
        :srcset="product.product_image"
        :src="product.product_image"
        class="product-card__image",
        :alt="product.name"
      )
    span.product-card__title.product-title
      | {{product.name}}
</template>

<script>
import { mapActions } from 'vuex'
import FontIcon from '@/components/FontIcon.vue'
import BadgedIcon from '@/components/BadgedIcon.vue'
import withBasketItems from '@/mixins/withBasketItems'
import actionSVG from '../assets/images/static/action.svg'
import newSVG from '../assets/images/static/new.svg'

export default {
  name: 'ProductCard',
  props: {
    data: Object
  },
  computed: {
    product () {
      return this.data.product
    }
  },
  mixins: [
    withBasketItems
  ],
  methods: {
    ...mapActions(['openAsidePanel']),
    buildIcon (product) {
      const obj = {
        show: false,
        image: '',
        text: ''
      }

      if (product.new) {
        obj.show = true
        obj.image = newSVG
        obj.text = this.$t('badges.new')
      }

      if (product.discount_price !== null && product.discount_price > 0) {
        obj.show = true
        obj.image = actionSVG
        obj.text = this.$t('badges.action')
      }

      if (product.displayed_text) {
        obj.text = product.displayed_text
      }

      return obj
    },
    onClickProduct () {
      const { product, group } = this.data
      this.openAsidePanel({ group, product })
    },
    getProductCardCountInBasket (product) {
      return this.basketItems.find(item => item.productId === product.id)
    },
    buildProductCardClass (product) {
      return {
        'category-products__item--grayscale': product.future_stock_movement !== null
      }
    }
  },
  components: { BadgedIcon, FontIcon }
}
</script>

<style scoped>
  .category-products__item--grayscale .product-card__preview,
  .category-products__item--grayscale .product-card__title {
    filter: grayscale(1);
    opacity: 0.5;
  }

  .product-img {
    width: 112px;
    height: 112px;
    object-fit: contain;
  }

  .product-title {
    margin: 0 auto;
  }

  .product-card__count {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    height: 24px;
    position: absolute;
    top: 5px;
    left: 11%;
    border-radius: 12px;
    background-image: linear-gradient(-43deg, #e83f94 0%, #f54e5e 100%);
    font-size: var(--text-size-secondary);
    text-align: center;
    z-index: 3;
    color: #fff;
  }

  .product-card__count-text {
    padding: 0 6px;
  }

  .product-card-icon {
    width: 55px;
    height: 55px;
    position: absolute;
    top: 65px;
    z-index: 3;
    left: 53%;
  }

  .product-card-icon__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .product-card-icon__text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    padding: 0 5px;
  }
</style>
