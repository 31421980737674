import axiosInstance from './axiosInstance'

export default class Auth {
  plural = '/auth';

  static init () {
    return new Auth()
  }

  login (credentials) {
    return axiosInstance.post(this.plural, credentials)
  }
}
