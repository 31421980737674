const mutations = {
  TOGGLE_IS_OPEN: 'POPUP/UI/TOGGLE_IS_OPEN',
  SET_POPUP_DATA: 'POPUP/UI/SET_POPUP_DATA',
  CLEAR_POPUP_DATA: 'POPUP/UI/CLEAR_POPUP_DATA'
}

export default {
  state: {
    isOpen: false,
    data: null
  },
  mutations: {
    [mutations.TOGGLE_IS_OPEN] (state, payload) {
      state.isOpen = payload
    },
    [mutations.SET_POPUP_DATA] (state, payload) {
      state.data = payload
    },
    [mutations.CLEAR_POPUP_DATA] (state) {
      state.data = null
    }
  },
  actions: {
    openPopup (ctx, payload) {
      ctx.dispatch('toggleBodyPopupClass', 'add')
      ctx.commit(mutations.SET_POPUP_DATA, payload)
      ctx.commit(mutations.TOGGLE_IS_OPEN, true)
    },
    closePopup (ctx) {
      ctx.dispatch('toggleBodyPopupClass', 'remove')
      ctx.commit(mutations.TOGGLE_IS_OPEN, false)
      ctx.commit(mutations.CLEAR_POPUP_DATA)
    },
    toggleBodyPopupClass (ctx, payload) {
      const [body] = document.getElementsByTagName('body')
      switch (payload) {
        case 'add':
          body.classList.add('locked')
          break
        case 'remove':
          body.classList.remove('locked')
          break
        default:
      }
    }
  },
  getters: {
    isShowPopup (state) {
      return state.isOpen
    },
    popupData (state) {
      return state.data
    }
  }
}
