<template lang="pug">
  .number
    .number__button.button.button--round.button--blue(
      @click="updateValue('subtract')"
    )
      | -
    input.number__input(
      type="number",
      :value="value",
      @change="onChangeInput"
      :data-min="minValue",
      :min="minValue",
      :step="0.5"
    )
    .number__button.button.button--round.button--blue(
      @click="updateValue('add')"
    )
      | +

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'NumberPicker',
  props: {
    min: {
      type: Number
    },
    step: {
      type: Number,
      default: 0.5
    },
    value: {
      type: Number,
      required: true
    }
  },
  computed: {
    minValue () {
      return this.min || this.step
    }
  },
  methods: {
    ...mapActions(['setLiveSessionState']),
    onChangeInput (e) {
      let newValue = parseFloat(e.target.value, 10)

      const isInvalid = newValue % this.step
      if (isInvalid) {
        const steps = Math.floor(newValue / this.step)
        newValue = this.step * steps
      }

      if (newValue < this.minValue || newValue > this.max) {
        e.target.value = this.value
        return
      }
      this.changeValue(newValue)
    },
    updateValue (type) {
      switch (type) {
        case 'add':
          this.add()
          break
        case 'subtract':
          this.subtract()
          break
        default:
      }
    },
    subtract () {
      const newValue = this.value - this.step
      if (newValue >= this.minValue) {
        this.changeValue(newValue)
      }
    },
    add () {
      const newValue = this.value + this.step
      this.changeValue(newValue)
    },
    changeValue (value) {
      this.setLiveSessionState(true)
      this.$emit('update:value', value)
      this.$emit('change', value)
    }
  }
}
</script>

<style scoped>

</style>
