import Vue from 'vue'
import { omit } from 'lodash'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      checkAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/ErrorPage.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.query.token) {
    localStorage.setItem('token', to.query.token)
    next({
      ...to,
      query: omit(to.query, ['token'])
    })
  }
  if (!to.meta.checkAuth) {
    next()
    return
  }

  if (!localStorage.getItem('token')) {
    next('/login')
    return
  }

  next()
})

export default router
