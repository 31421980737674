<template lang="pug">
  .table-wrap.table-documents
    table.table.table--items
      thead
        tr
          th(style="width: 200px") {{$t('tables.documents.number')}}
          th.is-desktop {{$t('tables.documents.orderNumbers')}}
          th.is-desktop {{$t('tables.documents.batchNumbers')}}
          th.is-desktop {{$t('tables.documents.invoiceNumber')}}
          th {{$t('tables.documents.amount')}}
          th {{$t('tables.documents.status')}}
          th.is-desktop(style="width: 100px") {{$t('tables.documents.shipment')}}
          th.is-desktop(style="width: 100px") {{$t('tables.documents.invoice')}}
      tbody
        fragment(
          v-for="document in documents"
          :key="document.id"
        )
          tr(
            :class="{'table__row--clicked': opened.has(document.id)}"
          )
            td(@click="toggleRow(document)")
              span.table__button-collapse: font-icon(name="chevron-down")
              | {{ document.number }}
            td.is-desktop(
              v-html="$options.filters.dataOrDash(document, 'order_numbers')"
            )
            td.is-desktop(
              v-html="$options.filters.dataOrDash(document, 'order_batch_numbers')"
            )
            td.is-desktop(
              v-html="$options.filters.dataOrDash(document, 'invoice_number')"
            )
            td {{ document.amount }} €
            td
              .tablet__flex-container
                label-status(:color="document.status | statusColor") {{document.status}}
              span.table__button-toggle
            td.is-desktop
              .tablet__flex-container
                icon-button(
                  :isLoading="documentsWaybillPdfDownloading.has(document.id)"
                  icon-name="download"
                  @click="downloadPdf(document, 'waybill')"
                )
            td.is-desktop
              .tablet__flex-container
                icon-button(
                  :isLoading="documentsInvoicePdfDownloading.has(document.id)"
                  icon-name="download"
                  :disabled="!document.customerInvoice"
                  @click="downloadPdf(document, 'invoice')"
                )
          // mobile
          tr.table__row.table__row--mobile(:class="{'table__row--open': opened.has(document.id)}")
            td(colspan="9")
              .custom-table
                .custom-table__row
                  .custom-table__cell {{$t('tables.documents.orderNumbers')}}
                  .custom-table__cell(
                    v-html="$options.filters.dataOrDash(document, 'order_numbers')"
                  )
                .custom-table__row
                  .custom-table__cell {{$t('tables.documents.batchNumbers')}}
                  .custom-table__cell(
                    v-html="$options.filters.dataOrDash(document, 'order_batch_numbers')"
                  )
                .custom-table__row
                  .custom-table__cell {{$t('tables.documents.invoiceNumber')}}
                  .custom-table__cell(
                    v-html="$options.filters.dataOrDash(document, 'invoice_number')"
                  )
                .custom-table__row
                  .custom-table__cell {{$t('tables.documents.shipment')}}
                  .custom-table__cell
                    .tablet__flex-container
                      icon-button(
                        :isLoading="documentsWaybillPdfDownloading.has(document.id)"
                        icon-name="download"
                        @click="downloadPdf(document, 'waybill')"
                      )
                .custom-table__row
                  .custom-table__cell {{$t('tables.documents.invoice')}}
                  .custom-table__cell
                    .tablet__flex-container
                      icon-button(
                        :isLoading="documentsInvoicePdfDownloading.has(document.id)"
                        icon-name="download"
                        :disabled="!document.customerInvoice"
                        @click="downloadPdf(document, 'invoice')"
                      )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import withCurrentCustomer from '@/mixins/withCurrentCustomer'
import statusColor from '@/filters/statusColor'
import dataOrDash from '@/filters/dataOrDash'
import IconButton from '@/components/IconButton.vue'
import LabelStatus from '@/components/LabelStatus.vue'
import FontIcon from '@/components/FontIcon.vue'

export default {
  name: 'DocumentsTable',
  components: {
    FontIcon,
    LabelStatus,
    IconButton
  },
  data () {
    return {
      opened: new Set()
    }
  },
  mixins: [
    withCurrentCustomer
  ],
  filters: {
    statusColor,
    dataOrDash
  },
  computed: {
    ...mapGetters(['documents', 'documentsWaybillPdfDownloading', 'documentsInvoicePdfDownloading'])
  },
  methods: {
    ...mapActions(['setLiveSessionState', 'downloadWaybillPdf', 'downloadInvoicePdf']),
    toggleRow (document) {
      this.setLiveSessionState(true)
      if (this.opened.has(document.id)) {
        this.opened.delete(document.id)
      } else {
        this.opened.add(document.id)
      }

      this.opened = new Set(this.opened)
    },
    async downloadPdf (document, type) {
      this.setLiveSessionState(true)
      switch (type) {
        case 'waybill':
          return this.downloadWaybillPdf({ customer: this.currentCustomer, document })
        case 'invoice':
          return this.downloadInvoicePdf({ customer: this.currentCustomer, document })
        default:
          throw new Error('invalid second argument')
      }
    }
  }
}
</script>

<style>
  .table-documents table.table--items th,
  .table-documents table.table--items td {
    text-align: center;
    display: table-cell !important;
    padding-left: 10px;
    padding-right: 10px;
  }

  .table-documents table.table--items td {
    width: auto;
  }

  .table-documents table.table--items th:first-child, .table-documents table.table--items td:first-child {
    padding-left: 30px;
    text-align: left;
  }

  .table-documents table.table--items th:last-child, .table-documents table.table--items td:last-child {
    padding-right: 30px;
  }

</style>
