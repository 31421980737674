const mutations = {
  SET_DATA: 'HEADER_DROPDOWN/UI/SET_DATA',
  CLEAR_DATA: 'HEADER_DROPDOWN/UI/CLEAR_DATA'
}

export default {
  state: {
    data: null
  },
  mutations: {
    [mutations.SET_DATA] (state, payload) {
      state.data = payload
    },
    [mutations.CLEAR_DATA] (state) {
      state.data = null
    }
  },
  actions: {
    toggleDropdown (ctx, payload) {
      if (ctx.state.data !== payload) {
        ctx.commit(mutations.SET_DATA, payload)
        return
      }
      ctx.commit(mutations.CLEAR_DATA)
    },
    closeDropdown (ctx) {
      ctx.commit(mutations.CLEAR_DATA)
    }
  },
  getters: {
    headerDropdownsState (state) {
      return state.data
    }
  }
}
