import { get } from 'lodash'
import Profile from '@/api/Profile'

const mutations = {
  SET_DATA: 'PROFILE/DATA/SET_DATA',
  SET_LIVE_SESSION: 'PROFILE/DATA/SET_LIVE_SESSION',
  SET_LOADING: 'PROFILE/DATA/SET_LOADING',
  CLEAR_DATA: 'PROFILE/DATA/CLEAR_DATA'
}

let liveSessionTimer = null

export default {
  state: {
    data: null,
    isLiveSession: true,
    isLoading: false
  },
  mutations: {
    [mutations.SET_DATA] (state, payload) {
      state.data = payload
    },
    [mutations.SET_LIVE_SESSION] (state, payload) {
      state.isLiveSession = payload
    },
    [mutations.SET_LOADING] (state, payload) {
      state.isLoading = payload
    },
    [mutations.CLEAR_DATA] (state) {
      state.data = null
    }
  },
  actions: {
    async loadProfile (ctx) {
      ctx.commit(mutations.SET_LOADING, true)
      try {
        const response = await Profile.init().get()
        ctx.commit(mutations.SET_DATA, response.data)

        let currentCustomerId = get(this.getters, 'appSettings.currentCustomerId')

        const { customers } = response.data
        const index = customers.findIndex(customer => customer.id === currentCustomerId)
        if (index === -1) {
          currentCustomerId = customers[0].id
          this.dispatch('setAppSettings', { currentCustomerId })
        }

        this.dispatch('loadProfileSubData', currentCustomerId)

        return response
      } finally {
        ctx.commit(mutations.SET_LOADING, false)
      }
    },
    setLiveSessionState (ctx, state) {
      ctx.commit(mutations.SET_LIVE_SESSION, state)
      clearTimeout(liveSessionTimer)
      if (state) {
        liveSessionTimer = setTimeout(() => ctx.commit(mutations.SET_LIVE_SESSION, false), process.env.VUE_APP_TIMEOUT_INACTIVE)
      }
    },
    loadProfileSubData (ctx, customerId) {
      this.dispatch('loadBanners', customerId)
      this.dispatch('loadShopData', customerId)
      liveSessionTimer = setTimeout(() => this.dispatch('setLiveSessionState', false), process.env.VUE_APP_TIMEOUT_INACTIVE)
    }
  },
  getters: {
    profile (state) {
      return state.data
    },
    profileIsLoading (state) {
      return state.isLoading
    },
    isLiveSession (state) {
      return state.isLiveSession
    }
  }
}
