import { findIndex } from 'lodash'

const mutations = {
  ADD_ITEM: 'BASKET/DATA/ADD_ITEM',
  REMOVE_ITEM: 'BASKET/DATA/REMOVE_ITEM',
  UPDATE_COUNT: 'BASKET/DATA/UPDATE_COUNT',
  CLEAR_DATA: 'BASKET/DATA/CLEAR_DATA',
  SET_BASKET_MESSAGE: 'BASKET/DATA/SET_BASKET_MESSAGE',
  CLEAR_MESSAGE: 'BASKET/DATA/CLEAR_MESSAGE'
}

export default {
  state: {
    data: JSON.parse(localStorage.getItem('basket') || '[]'),
    message: localStorage.getItem('basketMessage') || ''
  },
  mutations: {
    [mutations.ADD_ITEM] (state, payload) {
      state.data.push(payload)
    },
    [mutations.UPDATE_COUNT] (state, { index, count }) {
      state.data = state.data.map((item, i) => {
        if (i !== index) {
          return item
        }

        return { ...item, count }
      })
    },
    [mutations.REMOVE_ITEM] (state, payload) {
      state.data = state.data.filter((_, index) => index !== payload)
    },
    [mutations.CLEAR_DATA] (state) {
      state.data = []
    },
    [mutations.SET_BASKET_MESSAGE] (state, payload) {
      state.message = payload
    },
    [mutations.CLEAR_MESSAGE] (state) {
      state.message = ''
    }
  },
  actions: {
    addItemToBasket (ctx, payload) {
      const {
        product, group, count, profile, customer
      } = payload
      const index = findIndex(
        ctx.state.data,
        o => o.groupId === group.id &&
          o.productId === product.id &&
          o.profileId === profile.id &&
          o.customerId === customer.id
      )

      if (index !== -1) {
        const prevCount = ctx.state.data[index].count + count
        ctx.dispatch('updateBasketItemCount', { index, count: prevCount })
        return
      }

      ctx.commit(mutations.ADD_ITEM, {
        count,
        productId: product.id,
        groupId: group.id,
        profileId: profile.id,
        customerId: customer.id
      })
      ctx.dispatch('saveBasket')
    },
    updateBasketItemCount (ctx, payload) {
      const { count } = payload
      ctx.commit(mutations.UPDATE_COUNT, { index: payload.index, count })
      ctx.dispatch('saveBasket')
    },
    removeItemFromBasket (ctx, payload) {
      ctx.commit(mutations.REMOVE_ITEM, payload)
      ctx.dispatch('saveBasket')
    },
    clearBasket (ctx) {
      ctx.commit(mutations.CLEAR_DATA)
      ctx.commit(mutations.CLEAR_MESSAGE)
      ctx.dispatch('saveBasket')
    },
    setBasketMessage (ctx, payload) {
      ctx.commit(mutations.SET_BASKET_MESSAGE, payload)
      ctx.dispatch('saveBasket')
    },
    saveBasket (ctx) {
      const str = JSON.stringify(ctx.state.data)
      localStorage.setItem('basket', str)
      localStorage.setItem('basketMessage', ctx.state.message)
    }
  },
  getters: {
    basket (state) {
      return state.data
    },
    basketMessage (state) {
      return state.message
    }
  }
}
