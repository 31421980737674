<template lang="pug">
  #popup-price-table.popup(:class="{'popup_open': isShowPopup}")
    .popup-overlay(@click="closePopup")
    .popup-container
      button(type="button" class="popup-container__close" @click="closePopup")
        font-icon(name="close")
      .popup-content
        h2.popup-content__title {{ dataObj.title }}
        .popup__text {{dataObj.message}}

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FontIcon from '@/components/FontIcon.vue'

export default {
  name: 'PopupPriceTable',
  methods: {
    ...mapActions(['closePopup'])
  },
  computed: {
    ...mapGetters(['isShowPopup', 'popupData']),
    dataObj () {
      return this.popupData || {}
    }
  },
  components: { FontIcon }
}
</script>

<style scoped>

</style>
