<template lang="pug">
  .empty-state
    .empty-state__container
      img(
        srcset="images/static/box.png, images/static/box@2x.png 2x"
        src="images/static/box.png"
        class="empty-state__image empty-state__image--orders"
        :alt="$t('messages.emptyOrders')"
      )
      .empty-state__label {{$t('messages.emptyOrders')}}
</template>

<script>
export default {
  name: 'EmptyOrdersState'
}
</script>

<style scoped>

</style>
