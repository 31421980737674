import {
  find, filter, get, sortBy, map
} from 'lodash'
import Shop from '@/api/Shop'

const mutations = {
  SET_NOMENCLATURE: 'NOMENCLATURE/DATA/SET_NOMENCLATURE',
  SET_NOMENCLATURE_ACTION: 'NOMENCLATURE/DATA/SET_NOMENCLATURE_ACTION',
  SET_PRODUCTS: 'NOMENCLATURE/DATA/SET_PRODUCTS',
  SET_PRODUCT_TYPES: 'NOMENCLATURE/DATA/SET_PRODUCT_TYPES',
  SET_PRODUCT_GROUP: 'NOMENCLATURE/DATA/SET_PRODUCT_GROUP',
  SET_PRODUCT_GROUPS: 'NOMENCLATURE/DATA/SET_PRODUCT_GROUPS',
  SET_PRICING_POLICIES: 'NOMENCLATURE/DATA/SET_PRICING_POLICIES',

  CLEAR_NOMENCLATURE: 'NOMENCLATURE/DATA/CLEAR_NOMENCLATURE',

  SET_LOADING: 'NOMENCLATURE/DATA/SET_LOADING'
}

export default {
  state: {
    data: [],

    nomenclature: [],
    nomenclatureAction: [],
    products: [],
    productTypes: [],
    productGroups: [],
    productGroup: [],
    pricingPolicies: [],

    loadings: {
      nomenclature: false,
      nomenclatureAction: false,
      products: false,
      productTypes: false,
      productGroups: false,
      pricingPolicies: false,
      shopData: false
    }
  },
  mutations: {
    [mutations.SET_NOMENCLATURE] (state, payload) {
      state.nomenclature = payload
    },
    [mutations.SET_NOMENCLATURE_ACTION] (state, payload) {
      state.nomenclatureAction = payload
    },
    [mutations.SET_PRODUCTS] (state, payload) {
      state.products = payload
    },
    [mutations.SET_PRODUCT_TYPES] (state, payload) {
      state.productTypes = payload
    },
    [mutations.SET_PRODUCT_GROUPS] (state, payload) {
      state.productGroups = payload
    },
    [mutations.SET_PRODUCT_GROUP] (state, payload) {
      state.productGroup = payload
    },
    [mutations.SET_PRICING_POLICIES] (state, payload) {
      state.pricingPolicies = payload
    },
    [mutations.CLEAR_NOMENCLATURE] (state) {
      state.nomenclature = []
    },
    [mutations.SET_LOADING] (state, payload) {
      state.loadings[payload.name] = payload.value
    }
  },
  actions: {
    async loadShopData (ctx, payload) {
      ctx.commit(mutations.SET_LOADING, { name: 'shopData', value: true })
      // eslint-disable-next-line no-prototype-builtins
      if (!(payload.hasOwnProperty('type') && payload.type === 'refresh')) {
        ctx.commit(mutations.CLEAR_NOMENCLATURE)
      }
      // eslint-disable-next-line no-prototype-builtins
      if (payload.hasOwnProperty('id')) {
        payload = payload.id
      }
      try {
        return Promise.all([
          ctx.dispatch('loadNomenclature', payload),
          ctx.dispatch('loadProducts', payload),
          ctx.dispatch('loadProductTypes', payload),
          ctx.dispatch('loadProductGroups', payload),
          ctx.dispatch('loadPricingPolicies', payload)
        ])
      } finally {
        ctx.commit(mutations.SET_LOADING, { name: 'shopData', value: false })
      }
    },

    async loadNomenclature (ctx, payload) {
      ctx.commit(mutations.SET_LOADING, { name: 'nomenclature', value: true })
      ctx.commit(mutations.SET_LOADING, { name: 'nomenclatureAction', value: true })
      try {
        const response = await Shop.init().loadNomenclature(payload)
        ctx.commit(mutations.SET_NOMENCLATURE, response.data.data)
        ctx.commit(mutations.SET_NOMENCLATURE_ACTION, response.data.shelf)
        return response
      } finally {
        ctx.commit(mutations.SET_LOADING, { name: 'nomenclature', value: false })
        ctx.commit(mutations.SET_LOADING, { name: 'nomenclatureAction', value: false })
      }
    },
    async loadProducts (ctx, payload) {
      ctx.commit(mutations.SET_LOADING, { name: 'products', value: true })
      try {
        const response = await Shop.init().loadProducts(payload)
        ctx.commit(mutations.SET_PRODUCTS, response.data)
        return response
      } finally {
        ctx.commit(mutations.SET_LOADING, { name: 'products', value: false })
      }
    },
    async loadProductTypes (ctx, payload) {
      ctx.commit(mutations.SET_LOADING, { name: 'productTypes', value: true })
      try {
        const response = await Shop.init().loadProductTypes(payload)
        ctx.commit(mutations.SET_PRODUCT_TYPES, response.data)
        return response
      } finally {
        ctx.commit(mutations.SET_LOADING, { name: 'productTypes', value: false })
      }
    },
    async loadProductGroups (ctx, payload) {
      ctx.commit(mutations.SET_LOADING, { name: 'productGroups', value: true })
      try {
        const response = await Shop.init().loadProductGroups(payload)
        ctx.commit(mutations.SET_PRODUCT_GROUPS, response.data)
        return response
      } finally {
        ctx.commit(mutations.SET_LOADING, { name: 'productGroups', value: false })
      }
    },
    async loadProductGroup (ctx, payload) {
      ctx.commit(mutations.SET_LOADING, { name: 'productGroup', value: true })
      ctx.commit(mutations.SET_PRODUCT_GROUP, null)
      try {
        const response = await Shop.init().loadProductGroup(payload.customer, payload.product)
        ctx.commit(mutations.SET_PRODUCT_GROUP, response.data)
        return response
      } finally {
        ctx.commit(mutations.SET_LOADING, { name: 'productGroup', value: false })
      }
    },
    async loadPricingPolicies (ctx, payload) {
      ctx.commit(mutations.SET_LOADING, { name: 'pricingPolicies', value: true })
      try {
        const response = await Shop.init().loadPricingPolicies(payload)
        ctx.commit(mutations.SET_PRICING_POLICIES, response.data)
        return response
      } finally {
        ctx.commit(mutations.SET_LOADING, { name: 'pricingPolicies', value: false })
      }
    }
  },
  getters: {
    shopData (state) {
      const productGroupsMapper = (productGroupItem) => {
        const pricingPolicies = filter(
          state.pricingPolicies,
          o => productGroupItem.pricingPolicies.includes(o.id)
        )
        const products = filter(state.products, o => productGroupItem.products.includes(o.id))

        const productGroup = find(state.productGroups, { id: productGroupItem.id })
        return {
          ...productGroup,
          pricingPolicies,
          products
        }
      }

      const productTypesMapper = (productTypeItem) => {
        const productType = find(state.productTypes, { id: productTypeItem.id })
        const productsCount = productTypeItem.productGroups
          .reduce((acc, item) => [...acc, ...item.products], []).length

        const productGroups = productTypeItem.productGroups.map(productGroupsMapper)

        return {
          ...productType,
          productsCount,
          productGroups
        }
      }

      return state.nomenclature.map(productTypesMapper)
    },

    isShopDataLoading (state) {
      return state.loadings.nomenclature ||
        state.loadings.nomenclatureAction ||
        state.loadings.products ||
        state.loadings.productTypes ||
        state.loadings.productGroups ||
        state.loadings.pricingPolicies
    },

    isNomenclatureLoading (state) {
      return state.loadings.nomenclature
    },
    isProductsLoading (state) {
      return state.loadings.products
    },
    isProductTypesLoading (state) {
      return state.loadings.productTypes
    },
    isProductGroupsLoading (state) {
      return state.loadings.productGroups
    },
    isPricingPoliciesLoading (state) {
      return state.loadings.pricingPolicies
    },

    nomenclature (state) {
      return state.nomenclature
    },
    nomenclatureAction (state) {
      return state.nomenclatureAction
    },
    products (state) {
      return state.products
    },
    productTypes (state) {
      return state.productTypes
    },
    productGroups (state) {
      return state.productGroups
    },
    productGroup (state) {
      return state.productGroup
    },
    pricingPolicies (state) {
      return state.pricingPolicies
    },
    progressBarItems (state) {
      const groupId = get(state, 'pricingPolicies.0.product_group_id')

      if (!groupId) {
        return []
      }

      const items = filter(state.pricingPolicies, { product_group_id: groupId })
      const ranges = map(
        sortBy(items, ['products_range']),
        item => item.products_range
      )

      return [0, ...ranges]
    }
  }
}
