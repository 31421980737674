import axiosInstance from './axiosInstance'

export default class Customer {
  plural = '/customers';

  static init () {
    return new Customer()
  }

  get () {
    return axiosInstance.get(this.plural)
  }
}
