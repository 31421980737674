<template lang="pug">
  .section-progressbar
    .container.section-progressbar__container
      .progressbar
        .progressbar__list
          .progressbar__label(
            v-for="(size, i) in progressBarItems"
            :style="buildStyle(i)"
            :class="meSumm | progressBarLabelClass(size)"
          ) {{size}} {{$t('units.me')}}
        .progressbar__line
          //.progressbar__percent(style="width: 40%;")
          .progressbar__percent(:style="progressBarPercentStyles")
            .progressbar__percent-labels.percent-labels(:class="percentLabelsClass")
              // .percent-labels__text Click product to start
              // .percent-labels__percent__text.percent-labels__text--red Min. order: 7 MEs
          .progressbar__percent-gradient(:style="progressBarPercentGradientStyles")
</template>

<script>
import { mapGetters } from 'vuex'
import { findLastIndex } from 'lodash'
import withBasketItems from '@/mixins/withBasketItems'

export default {
  name: 'ProgressBar',
  filters: {
    progressBarLabelClass (data, count) {
      return { 'progressbar__label--gray': data < count }
    }
  },
  mixins: [
    withBasketItems
  ],
  methods: {
    buildStyle (i) {
      let style = ''
      const count = this.progressBarItems.length - 1

      if (i > 0 && i < count) {
        style += `position: absolute; left: ${100 / count * i}%; transform: translateX(-50%); margin-left: -3px;`
      }

      return style
    }
  },
  computed: {
    ...mapGetters(['progressBarItems']),
    meSumm () {
      return this.basketItems.reduce((acc, item) => acc + item.count, 0)
    },
    progressBarPercent () {
      const progressSizeIndex = findLastIndex(this.progressBarItems, item => item <= this.meSumm)
      const stepsLen = this.progressBarItems.length - 1
      const fixedPercent = 100 / stepsLen * progressSizeIndex

      const nextValue = this.progressBarItems[progressSizeIndex + 1]
      const currentValue = this.progressBarItems[progressSizeIndex]
      if (!nextValue || currentValue === this.meSumm) {
        return fixedPercent
      }

      const percentsInStep = 100 / stepsLen
      const meInStep = nextValue - currentValue
      const percent = percentsInStep / meInStep

      const additional = this.meSumm - currentValue
      const additionalPercents = additional * percent

      return fixedPercent + additionalPercents
    },
    progressBarPercentStyles () {
      return {
        width: `${this.progressBarPercent}%`
      }
    },
    progressBarPercentGradientStyles () {
      return {
        left: `${100 / (this.progressBarItems.length - 1)}%`
      }
    },
    percentLabelsClass () {
      return { 'percent-labels--revert': this.progressPercent >= 70 }
    }
  }
}
</script>

<style scoped>
</style>
