<template lang="pug">
  i(:class="iconClass" :style="styleName")
</template>

<script>
export default {
  name: 'FontIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    className: [String, Object],
    styleName: [String, Object]
  },
  computed: {
    iconClass () {
      return [`zmdi zmdi-${this.name}`, this.class]
    }
  }
}
</script>

<style scoped>

</style>
