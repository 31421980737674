<template lang="pug">
  section#category-cheetos.category-products
    h2.h2.category-products__title {{ data.name }}
      span.category-products__title-counter {{data.productsCount}} {{$t('category.tastes')}}
    // section
    products-section(
      v-for="productGroup in data.productGroups"
      :data="productGroup"
      :key="productGroup.id"
    )
</template>

<script>
import ProductsSection from '@/components/ProductsSection.vue'

export default {
  name: 'Category',
  props: {
    data: Object
  },
  components: { ProductsSection }
}
</script>

<style scoped>

</style>
