<template lang="pug">
  aside#panel-aside.panel-aside(ref="panel" :class="`panel-aside--${asidePanelStateVisible}`")
    button.button-compact.panel-aside__button-close.js-close-panel(
      @click="closeHandler"
      type="button"
    )
      font-icon(name="close", class-name="button-compact__icon")
    //- product
    .panel-aside__container.product-block
      .product-block__header
        h3.product-block__title {{product.name}}
        .product-block__preview
          img(
            :src="product.product_image"
            class="product-block__image"
            :alt="product.name"
          )
        .product-block__badges
          .badge(v-for="tag in product.productTags" :key="tag.id")
            font-icon(:name="tag.icon" :style="{color: tag.color}")
            span.badge__text {{tag.name}}

        //- controls
        .product-block__controls(
          v-if="product.future_stock_movement !== null"
        )
          button(
            data-button-add="cart"
            type="button"
            :class="getSubscribeButtonClass(product)"
            @click="subscribeItem"
          )
            font-icon.button__icon--subscribe-complete(
              name="check", class-name="button__icon"
            )
            span.button__text.button-subscribe__text {{$t('buttons.subscribe')}}
            span.button__text.button-subscribe__text--complete {{$t('buttons.subscribed')}}

        .product-block__controls(
          v-else
        )
          number-picker(
            :value.sync="count"
            :step="1 / productsCount"
          )
          button(
            v-if="basketItems.find(item => item.productId === product.id) !== undefined"
            data-button-add="cart"
            type="button"
            class="button button--blue product-block__button"
            @click="updateItem"
          )
            span.button__text {{$t('buttons.add')}}
          button(
            v-else
            data-button-add="cart"
            type="button"
            class="button button--blue product-block__button"
            @click="addItem"
          )
            span.button__text {{$t('buttons.add')}}
      .product-block__content
        .product-block__content-inner.js-collapse-content.js-collapse-content--show
          //- barcode section
          .product-block__barcode.barcode-section
            .barcode-section__container
              .barcode-section__item
                .barcode-section__title {{$t('panelAsideTitles.productBarcode')}}
                span.barcode {{product.product_barcode}}
              .barcode-section__item
                .barcode-section__title {{$t('panelAsideTitles.packageBarcode')}}
                span.barcode {{product.package_barcode}}
          //- description
          .product-block__description(
            v-if="product.description && product.description !== '<p><br></p>'"
          )
            button(
              v-if="!isOpenDescription"
              class="button button--blue button--small js-collapse-button"
              @click="isOpenDescription = true"
            )
              span.button__text {{$t('buttons.showMore')}}
              font-icon(name="chevron-down", class-name="button__icon")
            .product-block__text.js-collapse-content(
              :class="{'js-collapse-content--show': isOpenDescription}"
              v-html="product.description"
            )
          //- amount
          .product-block__amount
            span 1 {{$t('units.me').toUpperCase()}} =
            |
            |
            span.pack {{productsCount}} x {{product.number_in_package}} {{$t('units.pack')}}
          //- brutto
          .product-block__brutto-info.brutto-block
            .brutto-block__item
              span.brutto-block__label {{$t('panelAsideTitles.weight')}}:
              span.brutto-block__value {{packWeight}} {{$t('units.kg')}}
            .brutto-block__item
              span.brutto-block__label {{$t('panelAsideTitles.volume')}}:
              span.brutto-block__value {{product.volume}}
            .brutto-block__item
              span.brutto-block__label {{$t('panelAsideTitles.bruttoWeight')}}:
              span.brutto-block__value {{product.brutto_weight}}
            .brutto-block__item
              span.brutto-block__label {{$t('panelAsideTitles.bruttoVolume')}}:
              span.brutto-block__value {{product.brutto_volume}}
            .brutto-block__item(v-if="product.deposit_enabled")
              span.brutto-block__label {{$t('panelAsideTitles.depositPrice')}}:
              span.brutto-block__value {{product.deposit_price}}
            .brutto-block__item(v-if="product.deposit_enabled")
              span.brutto-block__label {{$t('panelAsideTitles.depositVat')}}:
              span.brutto-block__value {{product.deposit_vat}}
</template>

<script>
import { get } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import FontIcon from '@/components/FontIcon.vue'
import NumberPicker from '@/components/NumberPicker.vue'
import withCurrentCustomer from '@/mixins/withCurrentCustomer'
import withBasketItems from '@/mixins/withBasketItems'

export default {
  name: 'AsidePanel',
  data () {
    return {
      count: 1,
      isOpenDescription: false
    }
  },
  mixins: [
    withBasketItems,
    withCurrentCustomer
  ],
  computed: {
    ...mapGetters(['asidePanelStateVisible', 'asidePanelState', 'profile', 'subscribes']),
    panelData () {
      return this.asidePanelState.data || {}
    },
    panelState () {
      return this.asidePanelStateVisible
    },
    product () {
      return this.panelData.product || {}
    },
    group () {
      return this.panelData.group || {}
    },
    productsCount () {
      return this.group.sales_unit_volume / this.product.number_in_package
    },
    packWeight () {
      return this.productsCount * this.product.weight
    },
    productCountInBasket () {
      const findedProduct = this.basketItems.find(item => item.productId === this.product.id)
      return findedProduct !== undefined ? findedProduct.count : 1
    }
  },
  watch: {
    panelData (newVal, prevVal) {
      const prevProductId = get(prevVal, 'product.id')
      const productId = get(newVal, 'product.id')
      this.count = this.productCountInBasket

      if (!prevProductId || !productId || prevProductId === productId) {
        return
      }
      this.isOpenDescription = false
    }
  },
  methods: {
    ...mapActions(['setLiveSessionState', 'closeAsidePanel', 'addItemToBasket', 'updateBasketItemCount', 'createSubscribe', 'getSubscribes', 'openPopup']),
    getSubscribeButtonClass (product) {
      let className = 'button button--blue product-block__button button-subscribe'

      if (this.subscribes.data) {
        if (this.subscribes.data.find(obj => obj.product === product.name) !== undefined) {
          className += ' button--disabled'
        }
      }

      if (this.$i18n.locale === 'en') {
        className += ' button-subscribe--locale-en'
      }

      return className
    },
    async subscribeItem () {
      this.setLiveSessionState(true)
      await this.createSubscribe({
        product_id: this.product.id
      })
      await this.getSubscribes()
    },
    updateItem () {
      this.setLiveSessionState(true)
      const findedProduct = this.basketItems.find(item => item.productId === this.product.id)

      this.updateBasketItemCount({
        index: findedProduct.index,
        count: this.count
      })

      this.closeAsidePanel()
      this.count = this.productCountInBasket
      this.isOpenDescription = false
    },
    addItem () {
      this.setLiveSessionState(true)
      this.addItemToBasket({
        product: this.product,
        group: this.group,
        count: this.count,
        profile: this.profile,
        customer: this.currentCustomer
      })
      this.closeAsidePanel()
      this.count = this.productCountInBasket
      this.isOpenDescription = false
    },
    closeHandler () {
      this.setLiveSessionState(true)
      this.count = this.productCountInBasket
      this.count = 1
      this.closeAsidePanel()
    }
  },
  components: { NumberPicker, FontIcon }
}
</script>

<style scoped>
  .product-block__amount {
    max-width: 150px;
  }
  span.pack {
    margin-left: 5px;
    display: inline-block;
  }
</style>
