import axiosInstance from './axiosInstance'

export default class Profile {
  plural = '/profile';

  static init () {
    return new Profile()
  }

  get () {
    return axiosInstance.get(this.plural)
  }
}
