import Shop from '@/api/Shop'

const mutations = {
  SET_DATA: 'ORDERS/DATA/SET_DATA',
  SET_LOADING: 'ORDERS/DATA/SET_LOADING',
  SET_CREATING: 'ORDERS/DATA/SET_CREATING',
  START_DOWNLOADING_PDF: 'ORDERS/DATA/START_DOWNLOADING_PDF',
  STOP_DOWNLOADING_PDF: 'ORDERS/DATA/STOP_DOWNLOADING_PDF',
  START_COPING_ORDER: 'ORDERS/DATA/START_COPING_ORDER',
  STOP_COPING_ORDER: 'ORDERS/DATA/STOP_COPING_ORDER',
  CLEAR_DATA: 'ORDERS/DATA/CLEAR_DATA'
}

export default {
  state: {
    data: [],
    isLoading: false,
    isCreating: false,
    pdfDownloading: new Set(),
    copingOrder: new Set()
  },
  mutations: {
    [mutations.SET_DATA] (state, payload) {
      state.data = payload
    },
    [mutations.SET_LOADING] (state, payload) {
      state.isLoading = payload
    },
    [mutations.SET_CREATING] (state, payload) {
      state.isCreating = payload
    },
    [mutations.START_DOWNLOADING_PDF] (state, payload) {
      const set = state.pdfDownloading
      set.add(payload)
      state.pdfDownloading = new Set(set)
    },
    [mutations.STOP_DOWNLOADING_PDF] (state, payload) {
      const set = state.pdfDownloading
      set.delete(payload)
      state.pdfDownloading = new Set(set)
    },
    [mutations.START_COPING_ORDER] (state, payload) {
      const set = state.copingOrder
      set.add(payload)
      state.copingOrder = new Set(set)
    },
    [mutations.STOP_COPING_ORDER] (state, payload) {
      const set = state.copingOrder
      set.delete(payload)
      state.copingOrder = new Set(set)
    },
    [mutations.CLEAR_DATA] (state) {
      state.data = []
    }
  },
  actions: {
    async loadOrders (ctx, payload) {
      ctx.commit(mutations.SET_LOADING, true)
      try {
        const response = await Shop.init().loadOrders(payload)
        ctx.commit(mutations.SET_DATA, response.data)
        return response
      } finally {
        ctx.commit(mutations.SET_LOADING, false)
      }
    },

    async downloadOrderPdf (ctx, payload) {
      ctx.commit(mutations.START_DOWNLOADING_PDF, payload.order.id)
      try {
        const fileName = `${payload.order.number}.pdf`
        return await Shop.init().downloadOrderPdf(payload.customer.id, payload.order.id, fileName)
      } finally {
        ctx.commit(mutations.STOP_DOWNLOADING_PDF, payload.order.id)
      }
    },

    async copyOrder (ctx, payload) {
      ctx.commit(mutations.START_COPING_ORDER, payload.order.id)
      try {
        const result = await Shop.init().copyOrder(payload.customer.id, payload.order.id)

        const response = await Shop.init().loadOrders(payload.customer.id)
        ctx.commit(mutations.SET_DATA, response.data)

        return result
      } finally {
        ctx.commit(mutations.STOP_COPING_ORDER, payload.order.id)
      }
    },

    async createPreOrder (ctx, payload) {
      ctx.commit(mutations.SET_CREATING, true)
      try {
        return await Shop.init().createPreOrder(payload.customer.id, payload.data)
      } finally {
        ctx.commit(mutations.SET_CREATING, false)
      }
    }
  },
  getters: {
    orders (state) {
      return state.data
    },
    isOrdersLoading (state) {
      return state.isLoading
    },
    isOrdersCreating (state) {
      return state.isCreating
    },
    ordersPdfDownloading (state) {
      return state.pdfDownloading
    },
    ordersCopingLoadings (state) {
      return state.copingOrder
    }
  }
}
