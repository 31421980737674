<template lang="pug">
  header.header(v-if="!profileIsLoading" :class="{'header--dark': openedDropdown}")
    .container.header__container
      .header__dropdown.dropdown-menu(
        v-if="profile"
        :class="{'dropdown-menu--open': openedDropdown === 'customer'}"
      )
        .dropdown-menu__text.js-dropdown-toggle(
          v-if="currentCustomer"
          @click="toggleDropdown('customer')"
        )
          | {{ currentCustomer.name }}
          font-icon(name="chevron-down")
        .dropdown-menu__list
          .dropdown-menu__container.container
            .dropdown-menu__item(v-for="customer in profile.customers" :key="customer.id")
              button.dropdown-menu__link(@click="onClickCustomer(customer)")
                span.dropdown-menu__market-name {{customer.name}}
                span.dropdown-menu__market-address {{customer.shipping_address}}
                font-icon(name="sign-in")
      //- manager information
      .header__information(v-if="currentCustomer")
        //- manager name
        .header__data
          font-icon(name="account")
          | {{ currentCustomer.user.name }}
        //- manager phone
        a(:href="`tel:${currentCustomer.user.phone}`",  class="header__data")
          font-icon(name="phone")
          | {{currentCustomer.user.phone}}
        //- manager phone
        a(:href="`mailto:${currentCustomer.user.email}`",  class="header__data")
          font-icon(name="email")
          | {{currentCustomer.user.email}}
      //- dropdown
      .header__dropdown.dropdown-menu(
        :class="{'dropdown-menu--open': openedDropdown === 'customerPerson'}"
      )
        .dropdown-menu__text.js-dropdown-toggle(
          v-if="profile"
          @click="toggleDropdown('customerPerson')"
        )
          | {{profile.name}}
          font-icon(name="chevron-down")
        .dropdown-menu__list
          .dropdown-menu__container.container
            .dropdown-menu__item.dropdown-menu__item--text-right.is-mobile(
              v-if="currentCustomer"
            )
              <span class="dropdown-menu__title">{{$t('header.manager')}}:</span>
              //- manager name
              .header__data
                font-icon(name="account")
                | {{ currentCustomer.user.name }}
              //- manager phone
              a(:href="`tel:${currentCustomer.user.phone}`",  class="header__data")
                font-icon(name="phone")
                | {{currentCustomer.user.phone}}
              //- manager phone
              a(:href="`mailto:${currentCustomer.user.email}`",  class="header__data")
                font-icon(name="email")
                | {{currentCustomer.user.email}}
            .dropdown-menu__item.dropdown-menu__item--text-right
              <span class="dropdown-menu__title is-mobile">{{$t('header.profile')}}:</span>
              button.dropdown-menu__link(@click="onClickLogout")
                | {{$t('buttons.logout')}}
                font-icon(name="sign-in")
  header.header.header--loading(v-else-if="profileIsLoading")
    .container.header__container
      //- dropdown
      .header__dropdown.header__dropdown--loading.skeleton
        .skeleton__line.skeleton__line--light.skeleton__line--size-s
      //- manager information
      .header__information
        //- manager name
        .header__data.header__data--loading.skeleton
          .skeleton__line.skeleton__line--light.skeleton__line--size-s
        //- manager phone
        .header__data.header__data--loading.skeleton
          .skeleton__line.skeleton__line--light.skeleton__line--size-s
        //- manager phone
        .header__data.header__data--loading.skeleton
          .skeleton__line.skeleton__line--light.skeleton__line--size-s
      //- dropdown
      .header__dropdown.header__dropdown--loading.skeleton
        .skeleton__line.skeleton__line--light.skeleton__line--size-s
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FontIcon from '@/components/FontIcon.vue'
import withCurrentCustomer from '@/mixins/withCurrentCustomer'

export default {
  name: 'AppHeader',
  props: {
    openedDropdown: [String, Object]
  },
  created () {
  },
  watch: {
    profileIsLoading (val) {
      if (!val) {
        if (this.$zendesk) {
          this.$zendesk.$on('loaded', () => {
            this.$zendesk.identify({
              name: this.profile.name || 'User not found',
              email: this.profile.email || 'Email not found',
              phone: this.profile.phone || 'Phone not found',
              organization: this.currentCustomer.name || ''
            })

            this.$zendesk.prefill({
              name: {
                value: this.profile.name || ''
              },
              email: {
                value: this.profile.email || ''
              },
              phone: {
                value: this.profile.phone || ''
              }
            })
          })
        }
      }
    }
  },
  methods: {
    ...mapActions(['setLiveSessionState', 'setAppSettings', 'loadProfileSubData', 'logout']),
    toggleDropdown (type) {
      this.setLiveSessionState(true)
      if (this.openedDropdown !== type) {
        this.$emit('update:openedDropdown', type)
        return
      }
      this.$emit('update:openedDropdown', null)
    },
    onClickLogout () {
      this.logout()
    },
    onClickCustomer (customer) {
      this.setLiveSessionState(true)
      this.setAppSettings({ currentCustomerId: customer.id })
      this.loadProfileSubData(customer.id)
      sessionStorage.setItem('customerID', customer.id)
      this.toggleDropdown('customer')
    }
  },
  computed: {
    ...mapGetters(['profile', 'profileIsLoading'])
  },
  mixins: [
    withCurrentCustomer
  ],
  components: {
    FontIcon
  }
}
</script>

<style scoped>

</style>
