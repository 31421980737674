<template lang="pug">
  #popup-price-table.popup(:class="{'popup_open': isOpenPriceTable}")
    .popup-overlay(@click="closePriceTablePopup")
    .popup-container
      button(type="button" class="popup-container__close" @click="closePriceTablePopup")
        font-icon(name="close")
      .popup-content
        h3.popup-content__title {{ data.name }}
        table.popup__table.table-price
          tbody
            tr
              th {{$t('modals.price.quantity')}}
              td(v-for="data in data.policies" :key="data.id") {{data.products_range}} ME
            tr
              th {{$t('modals.price.price')}}
              td(v-for="data in data.policies" :key="data.id") {{data.price}} €
        .popup__text.popup__info(
          v-if="data.info"
          v-html="data.info"
        )
        img.popup__banner(
          v-if="data.banner"
          :src="data.banner"
        )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FontIcon from '@/components/FontIcon.vue'

export default {
  name: 'PopupPriceTable',
  methods: {
    ...mapActions(['closePriceTablePopup'])
  },
  computed: {
    ...mapGetters(['priceTablePopupState', 'isOpenPriceTable']),
    data () {
      return {
        policies: this.priceTablePopupState.policies || [],
        name: this.priceTablePopupState.name || '',
        info: this.priceTablePopupState.info || '',
        banner: this.priceTablePopupState.banner || ''
        // info: this.priceTablePopupState.info || `Looking for Dr Pepper with at 10-2-4? 🤔
        // Well here’s the one. Ela was one of our first Pepper <a href="#">Pack members</a>`,
        // banner: this.priceTablePopupState.banner || 'https://gtp.americansoda.demo.crmplease.me/uploads/images/banners/image/397x240_crop/DQ2dbNj5wwsOCdRS.jpg',
      }
    }
  },
  components: { FontIcon }
}
</script>

<style scoped>

</style>
