const equal = require('fast-deep-equal')

const mutations = {
  TOGGLE_PANEL: 'ASIDE_PANEL/UI/TOGGLE_VISIBLE',
  SET_DATA: 'ASIDE_PANEL/UI/SET_DATA',
  CLEAR_DATA: 'ASIDE_PANEL/UI/CLEAR_DATA'
}

export default {
  state: {
    data: null,
    panelState: 'close'
  },
  mutations: {
    [mutations.SET_DATA] (state, payload) {
      state.data = payload
    },
    [mutations.CLEAR_DATA] (state) {
      state.data = null
    },
    [mutations.TOGGLE_PANEL] (state, payload) {
      state.panelState = payload
    }
  },
  actions: {
    openAsidePanel (ctx, payload) {
      if (ctx.state.data == null || (payload !== undefined && equal(ctx.state.data, payload))) {
        ctx.commit(mutations.SET_DATA, payload)
        setTimeout(() => {
          ctx.commit(mutations.TOGGLE_PANEL, 'open')
        }, 500)
      } else {
        ctx.commit(mutations.TOGGLE_PANEL, 'close')
        setTimeout(() => {
          ctx.commit(mutations.SET_DATA, payload)
        }, 375)
        setTimeout(() => {
          ctx.commit(mutations.TOGGLE_PANEL, 'open')
        }, 750)
      }
    },
    closeAsidePanel (ctx) {
      ctx.commit(mutations.TOGGLE_PANEL, 'close')
      setTimeout(() => {
        ctx.commit(mutations.CLEAR_DATA)
      }, 500)
    }
  },
  getters: {
    asidePanelState (state) {
      return {
        data: state.data
      }
    },
    asidePanelStateVisible (state) {
      return state.panelState
    }
  }
}
