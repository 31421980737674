/* eslint-disable import/no-duplicates */
import Vue from 'vue'
import Vuex from 'vuex'

import asidePanel from './modules/ui/asidePanel'
import headerDropdowns from './modules/ui/headerDropdowns'
import priceTablePopup from './modules/ui/priceTablePopup'
import popup from './modules/ui/popup'
import orderPanel from './modules/ui/orderPanel'

import appSettings from './modules/data/appSettings'

import customer from './modules/data/customer'
import auth from './modules/data/auth'
import profile from './modules/data/profile'
import banners from './modules/data/banners'
import settings from './modules/data/settings'
import nomenclature from './modules/data/nomenclature'
import orders from './modules/data/orders'
import subscribes from './modules/data/subscribe'
import documents from './modules/data/documents'
import basket from './modules/data/basket'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiUrl: process.env.VUE_APP_API_URL
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    /* ui */
    asidePanel,
    headerDropdowns,
    priceTablePopup,
    orderPanel,
    popup,
    /* end ui */

    appSettings,

    /* data */
    auth,
    customer,
    profile,
    banners,
    settings,
    nomenclature,
    orders,
    subscribes,
    documents,

    basket
    /* end data */
  },

  getters: {
    apiUrl: state => state.apiUrl
  }
})

// export default {
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   getters: {
//   },
// };
