const mutations = {
  SELECT_TAB: 'ORDER_PANEL/UI/SELECT_TAB',
  CHANGE_IS_OPENED: 'ORDER_PANEL/UI/CHANGE_IS_OPENED'
}

export default {
  state: {
    currentTab: 'items',
    isOpened: false
  },
  mutations: {
    [mutations.SELECT_TAB] (state, payload) {
      state.currentTab = payload
    },
    [mutations.CHANGE_IS_OPENED] (state, payload) {
      state.isOpened = payload
    }
  },
  actions: {
    selectPanelOrderTab (ctx, payload) {
      ctx.commit(mutations.SELECT_TAB, payload)
    },
    togglePanelOrder (ctx, payload) {
      ctx.commit(mutations.CHANGE_IS_OPENED, payload)
    }
  },
  getters: {
    currentPanelOrderTab (state) {
      return state.currentTab
    },
    isOpenedPanelOrder (state) {
      return state.isOpened
    }
  }
}
